import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home.js";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import Productlist from "./Pages/Productlist";
import Productdetail from "./Pages/Productdetail";
import Cart from "./Pages/Cart";
import Contact from "./Pages/Contact";
import Personal from "./Pages/Personal";
import ShippingChange from "./Pages/ShippingChange";
import Category from "./Pages/Category";
import SellerStore from "./Pages/SellerStore";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Termscondition from "./Pages/Termscondition";
import Pagenotfound from "./Pages/Pagenotfound";
import Usercartlist from "./Pages/Usercartlist";
import HelpTopics from "./Pages/HelpTopics";
import Test from "./Pages/Test";
import Faq from "./Pages/Faq";
import Login from "./user/Login";
import ForgotPassword from "./user/ForgotPassword";
import ResetPassword from "./user/ResetPassword";
import Register from "./user/Register";
import Authentication from "./user/Authentication";
import ProductlistBySubcat from "./product/ProductlistBySubcat";
import Singleproduct from "./product/Singleproduct";
import ProductByCategory from "./product/ProductByCategory";
import Productsearch from "./product/Productsearch";
import UserDashboard from "./user/UserDashboard";
import { Helmet } from "react-helmet";
import Checkout from "./checkoutpage/Checkout";
import ShippingInfo from "./checkoutpage/ShippingInfo";
import UserOrderDetails from "./product/UserOrderDetails";
import UserWishlist from "./user/UserWishlist";
import About from "./Pages/About/About.js";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const user = localStorage.getItem("userDetails");

  return (
    <>
      <Router>
        <Header />
        <Helmet>
          <title>Welcome to Mangtum</title>
          <meta
            name="description"
            content="Mangtum | Shop and Sell Handmade jewelry and crafts, to vintage clothing and supplies"
          />
          <meta
            name="keywords"
            content="Mangtum global marketplace for selling and buying Products & Services from around the world. From handmade jewelry and crafts, to vintage clothing and supplies you'll find what you're creative. "
          />
        </Helmet>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/productlist" element={<Productlist />} />
          <Route exact path="/productdetail" element={<Productdetail />} />
          <Route exact path="/cart" element={user ? <Cart /> : <Login />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/personal" element={<Personal />} />
          <Route exact path="/shippingchange" element={<ShippingChange />} />
          <Route exact path="/category" element={<Category />} />
          <Route exact path="/sellerstore" element={<SellerStore />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-condition"
            element={<Termscondition />}
          />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/authentication" element={<Authentication />} />
          <Route
            exact
            path="/product-category/*"
            element={<ProductlistBySubcat />}
          />
          <Route exact path="/shop/*" element={<Singleproduct />} />
          <Route
            exact
            path="/product-by-category/*"
            element={<ProductByCategory />}
          />
          <Route exact path="/page-not-found" element={<Pagenotfound />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/product-search" element={<Productsearch />} />
          <Route
            exact
            path="/my-cart"
            element={user ? <Usercartlist /> : <Login />}
          />
          <Route
            exact
            path="/user-dashboard"
            element={user ? <UserDashboard /> : <Login />}
          />
          <Route exact path="/help-topics" element={<HelpTopics />} />
          <Route
            exact
            path="/checkout"
            element={user ? <Checkout /> : <Login />}
          />
          <Route exact path="/shipping-info" element={<ShippingInfo />} />
          {/* <Route path="/reset-password/1edee312-9141-6170-8bed-6bbc4f1719e0" element={<ResetPassword/>} /> */}
          <Route path="/reset-password/*" element={<ResetPassword />} />
          <Route
            exact
            path="/user-order-details"
            element={<UserOrderDetails />}
          />
          <Route exact path="/test" element={<Test />} />
          <Route
            exact
            path="/userwishlist"
            element={user ? <UserWishlist /> : <Login />}
          />
          {/* <Route path='/blogs' element={<Blogs/>} /> */}
          {/* <Route path='/sign-up' element={<SignUp/>} /> */}
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
