import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { images } from "../../utils/images";
import { getFiterResponse } from "../../store/product";
import { useDispatch } from "react-redux";

const Filters = ({ options, setSmallScreen }) => {

  const dispatch = useDispatch();

  const {
    min_max_price = {},
    filters = [],
    categoryWithSubCategory = {},
    parent_categories = [],
  } = options;

  const [values, setValues] = useState([]);

  const location = useLocation().pathname.split('/');

  useEffect(() => {
    if (min_max_price.min_price && min_max_price.max_price) {
      setValues([min_max_price.min_price, min_max_price.max_price]);
    }
  }, [min_max_price]);

  const handleSliderChange = (newValues) => {
    setValues(newValues);
  };

  const handleMinInputChange = (e) => {
    const newMinValue = parseInt(e.target.value);
    if (newMinValue > values[1]) {
      setValues([values[1], values[1]]);
    } else {
      setValues([newMinValue, values[1]]);
    }
  };
  const handleMaxInputChange = (e) => {
    const newMaxValue = parseInt(e.target.value);
    if (newMaxValue <= values[0]) {
      setValues([values[0], values[0]]);
    } else {
      setValues([values[0], newMaxValue]);
    }
  };

  const [openReplyMenuIndex, setOpenReplyMenuIndex] = useState([]);
  const handleReplyClick = (index) => {
    if (openReplyMenuIndex.includes(index)) {
      setOpenReplyMenuIndex(
        openReplyMenuIndex.filter((item) => item !== index)
      );
    } else {
      setOpenReplyMenuIndex([...openReplyMenuIndex, index]);
    }
  };
  const validFilter = Array.isArray(filters) ? filters : [];

  const [filterData, setFilterData] = useState([]);

  function handleCheckbox(rowIndex, itemIndex) {
    setFilterData(prev=>[...prev, validFilter[rowIndex].options[itemIndex]])
  }

  return (
    <div className="side-filter">
      <div>
        <div className="side-flex">
          <h4>Categories</h4>
          <button className="svg-icon" onClick={(e) => setSmallScreen(false)}>
            <img src={images["x.svg"]} alt="" />
          </button>
        </div>
        {parent_categories?.length > 0 && (
          <ul>
            {parent_categories.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={`/${item.slug}`}>{item.cate_name}</Link>
                  {categoryWithSubCategory?.cat_id === item.cat_id && (
                    <ul>
                      {categoryWithSubCategory.subcategories.map(
                        (subCategoryItem, idx) => {
                          return (
                            <li key={idx}>
                              <Link
                                to={`/product-category/${
                                  item.slug
                                }/${subCategoryItem.subcate_name
                                  .toLowerCase()
                                  .replace(/\s/g, "-")}`}
                              >
                                {subCategoryItem.subcate_name}
                              </Link>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div>
        <h4>Filters</h4>
        <ul className="nested-filter">
          {validFilter?.length > 0 &&
            validFilter?.map((item, index) => (
              <li key={index} className="title-li">
                <span
                  className="att-title"
                  onClick={(e) => handleReplyClick(index)}
                >
                  {item.attr_name}
                </span>
                <i
                  className="fa fa-caret-down"
                  style={{ cursor: "pointer", width: "10%" }}
                  onClick={(e) => handleReplyClick(index)}
                ></i>
                {openReplyMenuIndex.includes(index) && (
                  <ul>
                    {item.options.length > 0 &&
                      item.options.map((optionItem, optionIndex) => (
                        <li key={optionIndex}>
                          <input
                            type="checkbox"
                            id={optionItem.attribute_value}
                            onChange={e=>handleCheckbox(index,optionIndex)}
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor={optionItem.attribute_value}
                          >
                            {optionItem.attribute_value}
                          </label>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </div>
      <div>
        <h4>Price</h4>
        <div className="price-inps">
          <div>
            <input
              type="number"
              value={values[0]}
              onChange={handleMinInputChange}
            />
            <span>$</span>
          </div>
          <div>
            <input
              type="number"
              value={values[1]}
              onChange={handleMaxInputChange}
            />
            <span>$</span>
          </div>
        </div>
        <div className="slider-container">
          <Slider
            min={parseInt(min_max_price.min_price)}
            max={parseInt(min_max_price.max_price)}
            range
            value={values}
            allowCross={false}
            onChange={handleSliderChange}
            dotStyle={{
              backgroundColor: "#f6a92c",
              borderColor: "#f6a92c",
              width: 24,
              height: 24,
            }}
          />
        </div>
        <button className="filter-bt" onClick={e=>dispatch(getFiterResponse({attr:filterData,prices:values, category:location[location.length-1]}))}>Filter</button>
      </div>
    </div>
  );
};

export default Filters;
