import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import "swiper/css";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { useDispatch, useSelector } from "react-redux";
import {
  getProductCartCount,
  getSingleproductDetail,
  getRelatedProducts,
  getVendorinfo,
  addWishlist,
} from "../store/product";
import { cartActions, userAddToCart } from "../store/cart";
import { getMycart } from "../store/home";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Add this to the parent component that contains the Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SiteLoader from "../SiteLoader";
let varientData = {};
const Singleproduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterUrl = window.location.pathname.split("shop/").pop();
  const productslug = filterUrl.split("/");
  const prodSlug = productslug[2];
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);

  var user_id = data?.ID;
  var isLogin = false;
  if (userDetails) {
    isLogin = true;
  } else {
    isLogin = false;
  }
  //const isLogin = useSelector((state) => state.auth.loginStatus);
  const [qty, setqty] = useState(1);
  const [invId, setInvId] = useState("");
  const [stockVal, setStockVal] = useState(0);
  const [regularPrice, setRegularPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  // const [loader, setLoaderStatus] = useState(true);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  useEffect(() => {
    dispatch(getSingleproductDetail({ prodSlug }));
  }, [prodSlug]);

  const [selectedColor, setSelectedColor] = useState("");
  const [prodQty, setProdQty] = useState(1);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const { loader, msg, status } = useSelector((state) => state.cart);
  const single_product_detail = useSelector(
    (state) => state.prodLi.singleProducts
  );
  const { statuskey, message } = useSelector((state) => state.prodLi);
  //console.log('single_product_detail',single_product_detail);
  const galleryI = useSelector((state) => state.prodLi.galleryimages);

  const product_id = single_product_detail?.product?.id;
  const subcatId = single_product_detail?.product?.sub_ids["0"];
  const st = useSelector((state) => state.prodLi);
  useEffect(() => {
    if (subcatId) {
      dispatch(getRelatedProducts({ subcatId, product_id, user_id }));
    }
  }, [subcatId, product_id, user_id]);
  const related_prodcts = useSelector((state) => state.prodLi.related_prod);

  const inDetails = useSelector((state) => state.prodLi.productVariations);

  useEffect(() => {
    varientData = {};
    single_product_detail?.product &&
      Object.entries(single_product_detail?.product.varients).map(
        ([varient, value]) => {
          varientData = { ...varientData, [varient]: "" };
        }
      );
    setStockVal(
      single_product_detail?.product && single_product_detail?.product.stock
    );
    setRegularPrice(
      single_product_detail?.product &&
        single_product_detail?.product?.regular_price
    );
    setSellPrice(
      single_product_detail?.product &&
        single_product_detail?.product?.sell_price
    );
  }, [single_product_detail?.product]);

  useEffect(() => {
    if (user_id && product_id) {
      dispatch(getProductCartCount(user_id, product_id));
    }
  }, [product_id]);

  const userCartProductCount = useSelector(
    (state) => state.prodLi.userCartProductCount
  );
  useEffect(() => {
    setqty(userCartProductCount);
  }, [userCartProductCount]);

  const [selectedRadio, setSelectedRadio] = useState(
    Array(inDetails?.variation?.length).fill(false)
  );
  const [arr, setArr] = useState([]);
  const inventory = () => {
    return inDetails?.variation?.map((varient, i) => {
      return (
        <>
          <div className="row" id="ads">
            <div className="col-md-4 ad-title">
              <h5>{varient.attr_name}</h5>
              <div className="cat action varient-attr">
                {varient.values?.map((attrValue) => {
                  const labelId = `labelId_${attrValue.id}`;
                  return (
                    <label
                      data-inv={attrValue.inv_id}
                      id={labelId}
                      className=""
                    >
                      <input
                        type="radio"
                        value={attrValue.attr_value_id}
                        onChange={() =>
                          handleRadioChange(
                            attrValue.attr_value_id,
                            i,
                            attrValue.inv_id,
                            attrValue.id
                          )
                        }
                        checked={selectedRadio[i] === attrValue.attr_value_id}
                      />
                      <span>{attrValue.attr_value}</span>
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  const inventory2 =
    single_product_detail &&
    single_product_detail.selected_attr &&
    single_product_detail.selected_attr.map((arr) => {
      return (
        <>
          {/* <div key={arr} className="verientClass">
        <p className="selectedVerients">
          {arr.attr_name}:{arr.attr_value}
        </p>
      </div> */}

          <p className="selectedVerients">
            {arr.attr_name}:{arr.attr_value}
          </p>
        </>
      );
    });

  //var arr = {}

  /*function handleRadioChange(value, i) {
   
    arr[i] = value;
    if (arr.length > 1) Object.keys(arr).sort()
    var data = Object.values(arr).toString().replaceAll(",","")
    document.getElementById("concat_invId").value = data;
   
  }*/
  function handleRadioChange(value, i, invid, id) {
    /**New code start */
    //labelId
    const allLabels = document.querySelectorAll("label");
    // Get all the label elements on the page
    // Loop through all the label elements and show/hide them based on their data-inv attribute value
    allLabels.forEach((label) => {
      const invValue = label.getAttribute("data-inv");
      ////console.log(invValue+'/'+invid);
      if (invValue == invid) {
        // If the data-inv attribute value matches the desired value, show the label
        label.classList.remove("hide");
      } else {
        // If the data-inv attribute value doesn't match, hide the label
        label.classList.add("hide");
      }
    });

    /**New code end */
    const newSelectedRadio = [...selectedRadio];
    newSelectedRadio[i] = value;
    setSelectedRadio(newSelectedRadio);
    arr[i] = value;
    if (arr.length > 1) Object.keys(arr).sort();
    setArr(arr);
    var data = Object.values(arr).toString().replaceAll(",", "");
    document.getElementById("concat_invId").value = data;
  }
  const cartInventory = (invType, event) => {
    varientData[invType] = event.target.value;
    const varient = Object.keys(varientData).every((k) => varientData[k]);
    const invenId = Object.values(varientData).flat().join("");
    const fitlered = single_product_detail.product.inventories.filter(
      (item) => item.inventory_id === parseInt(invenId)
    );

    if (varient) {
      setInvId(fitlered[0]?.id);
    }
    fitlered.length > 0
      ? setStockVal(fitlered[0].stocks)
      : setStockVal(single_product_detail.product.stock);
    fitlered.length > 0
      ? setRegularPrice(fitlered[0].regular_price)
      : setRegularPrice(single_product_detail.product.regular_price);
    fitlered.length > 0
      ? setSellPrice(fitlered[0].sell_price)
      : setSellPrice(single_product_detail.product.sell_price);
  };

  const addToCart = (pro_slug) => {
    if (!isLogin) {
      navigate("/login");
    } else {
      // Check if at least one option has been selected from each variation Pramodini
      ////console.log('inDetails',);
      // const isVariationSelected = inDetails?.variation?.every((variation, index) => {
      //   const selectedOption = selectedRadio[index];
      //   return selectedOption && variation.values.find(val => val.attr_value_id === selectedOption)?.attr_value_id === selectedOption;
      // });
      const isVariationSelected =
        !inDetails ||
        !inDetails.variation ||
        inDetails.variation.every((variation, index) => {
          const selectedOption = selectedRadio[index];
          return (
            selectedOption &&
            variation.values.find((val) => val.attr_value_id === selectedOption)
              ?.attr_value_id === selectedOption
          );
        });
      if (!isVariationSelected) {
        document.getElementById("v_msg").innerHTML =
          '<p class ="errorpass" style ="color: red">Please select at least one option from each variation</p>';
        //alert('Please select at least one option from each variation');
      } else {
        document.getElementById("v_msg").innerHTML = "";

        const varient = Object.keys(varientData).length;
        const remainedFitlered = Object.keys(varientData).filter(
          (item) => varientData[item] === ""
        );
        // //console.log("")
        const proName = single_product_detail.product.pro_name;
        const cartId = pro_slug + invId;
        const img_url = single_product_detail.product.img_url;
        const short_desc = single_product_detail.product.short_desc;
        const web = single_product_detail.product.product_url.web;

        console.log("selectedAttributes", selectedAttributes);
        console.log("combinartions ", combinationKey);
        const sku =
          single_product_detail?.product_variations?.combinations[
            combinationKey
          ]?.sku;
        const color = selectedColor;
        console.log("color", color);
        console.log("sku", sku);

        let selectedVarients = [];
        for (const property in varientData) {
          if (varientData[property] != "") {
            let newObj = {};
            newObj[property] = varientData[property];
            selectedVarients.push(newObj);
          }
        }

        // console.log("sele")
        const product_id = single_product_detail.product?.id;
        var inven_id = document.getElementById("concat_invId").value;

        if (!varient) {
          isLogin
            ? dispatch(
                userAddToCart({
                  pro_slug,
                  qty,
                  proName,
                  invId,
                  sellPrice,
                  cartId,
                  img_url,
                  short_desc,
                  web,
                  product_id,
                  user_id,
                  inven_id,
                  sku,
                  color,
                  selectedAttributes,
                })
              )
            : dispatch(
                cartActions.addToCartPro({
                  pro_slug,
                  qty,
                  proName,
                  invId,
                  sellPrice,
                  cartId,
                  img_url,
                  short_desc,
                  web,
                  product_id,
                  user_id,
                  inven_id,
                  sku,
                  color,
                  selectedAttributes,
                })
              );
          // !isLogin && toast.success('Product added to cart');
        } else if (single_product_detail?.product?.product_type == 0) {
          isLogin
            ? dispatch(
                userAddToCart({
                  pro_slug,
                  qty,
                  proName,
                  invId,
                  sellPrice,
                  cartId,
                  img_url,
                  short_desc,
                  web,
                  product_id,
                  user_id,
                  inven_id,
                  sku,
                  color,
                  selectedAttributes,
                })
              )
            : dispatch(
                cartActions.addToCartPro({
                  pro_slug,
                  qty,
                  proName,
                  invId,
                  sellPrice,
                  cartId,
                  img_url,
                  short_desc,
                  web,
                  product_id,
                  user_id,
                  inven_id,
                  sku,
                  color,
                  selectedAttributes,
                })
              );
        } else {
          const isSuccess = Object.keys(varientData).every(
            (k) => varientData[k]
          );
          // isSuccess?
          isLogin
            ? dispatch(
                userAddToCart({
                  pro_slug,
                  qty,
                  proName,
                  invId,
                  sellPrice,
                  cartId,
                  img_url,
                  short_desc,
                  web,
                  product_id,
                  user_id,
                  inven_id,
                  sku,
                  color,
                  selectedAttributes,
                })
              )
            : dispatch(
                cartActions.addToCartPro({
                  pro_slug,
                  qty,
                  proName,
                  invId,
                  sellPrice,
                  cartId,
                  img_url,
                  short_desc,
                  web,
                  selectedVarients,
                  product_id,
                  user_id,
                  inven_id,
                  sku,
                  color,
                  selectedAttributes,
                })
              );

          //: toast.error("Please select " + remainedFitlered);
        }
        getMycart();
        // toast("Product added successfully");
      }
    }
  };
  const showimagegalley = () => {
    //const galleryIm = Object.keys(galleryI).map(k => galleryI[k])
    {
      galleryI.map((imgList) => {
        return (
          <>
            <div className="swiper-slide">
              <img src={imgList["1"]} />
            </div>
          </>
        );
      });
    }
  };
  //const galleryIm = Object.keys(galleryI).map(k => galleryI[k])

  //Gallery zoomview code started
  const lightGallery = useRef(null);

  // const getItems = useCallback(() => {

  //   return galleryI.map((item) => {
  //     return (
  //       <a
  //         key={item.id}
  //         data-lg-size={item['1']}
  //         className="gallery-item"
  //         data-src={item['1']}
  //       >
  //         <img
  //           style={{ maxWidth: '80px' }}
  //           className="img-responsive"
  //           src={item['1']}
  //         />
  //       </a>
  //     );
  //   });
  // }, [galleryI]);

  // const images = [
  //   {
  //     original: single_product_detail?.product?.main_image,
  //     thumbnail: single_product_detail?.product?.main_image,
  //   },
  //   ...galleryI.map((imgList, index) => ({
  //     original: imgList['1'],
  //     thumbnail: imgList['1'],
  //   })),
  // ];

  const getItems = useCallback(() => {
    return galleryI.map((item, index) => {
      return (
        <a
          key={index}
          data-lg-size={item}
          className="gallery-item"
          data-src={item}
        >
          <img
            style={{ maxWidth: "80px" }}
            className="img-responsive"
            src={item}
            alt={`Image ${index}`}
          />
        </a>
      );
    });
  }, [galleryI]);

  const images = [
    {
      original: single_product_detail?.product?.main_image,
      thumbnail: single_product_detail?.product?.main_image,
      originalHeight: 350,
      originalWidth: 350,
      thumbnailHeight: 90,
      thumbnailWidth: 60,
    },
    ...galleryI.map((imgList) => ({
      original: imgList,
      thumbnail: imgList,
      originalHeight: 350,
      originalWidth: 350,
      thumbnailHeight: 90,
      thumbnailWidth: 60,
    })),
  ];
  const [activeTab, setActiveTab] = useState("description");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const [activeTabNew, setActiveTabNew] = useState("vp");
  const handleTabChangeNew = (tab) => {
    setActiveTabNew(tab);
  };

  const handleOrderClick = (vendor_id, shipping_profile) => {
    navigate("/sellerstore", {
      state: {
        vendor_id: vendor_id,
        shipping_profile: shipping_profile,
      },
    });
  };
  useEffect(() => {
    dispatch(
      getVendorinfo(
        single_product_detail?.product?.shipping_profile?.id,
        single_product_detail?.product?.vendor_id
      )
    );
  }, [
    single_product_detail?.product?.shipping_profile?.id,
    single_product_detail?.product?.vendor_id,
  ]);

  const store_info_tab = useSelector((state) => state.prodLi.vendorInfo);

  //scroll top functionality start
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // scroll top functionality end
  // Add to wishlist start
  const addToWishList = (product_id) => {
    dispatch(addWishlist(product_id));

    var heartIcon = document.getElementById("heartIcon");
    heartIcon.classList.toggle("selected");
  };
  //wishlist_status
  const wish = useSelector((state) => state.prodLi.wishlist_status);
  //console.log(wish);
  useEffect(() => {
    if (wish == 1) {
      var element = document.getElementsByClassName("wish-class")[0];
      if (element) {
        element.style.color = "red";
      }

      // toast("Product added to the wishlist.");
    }
    if (wish == 0) {
      var element = document.getElementsByClassName("wish-class")[0];
      if (element) {
        element.style.color = "#ffc107";
      }
      // toast("Product removed from the wishlist.");
    }
  }, [wish]);
  // Add to wishlist end

  var vari = [];
  // useEffect(() => {
  //   var attributes  = []
  //   single_product_detail.product?.custom_attrs.map(item => {
  //     attributes.push(item);
  //     //console.log("attributes", item)
  //   })
  //   var attribute_values = single_product_detail.product.custom_values

  //   single_product_detail.product?.custom_attrs.map(item => {
  //     //console.log("attributes value ", item)
  //   })
  // }, [single_product_detail.product])

  const getVariations = () => {
    var attributes = [];
    single_product_detail.product?.custom_attrs.map((item) => {
      attributes.push(item);
      //console.log("attributes", item)
    });

    var attribute_values = single_product_detail.product?.custom_values;
    //console.log("custom values", attribute_values)
    return (
      <>
        {attributes.map((item) => {
          <span>{item}</span>;
          attribute_values?.map((item1) => {
            <span>{item1}</span>;
          });
        })}
      </>
    );
  };

  const handleQuantityChange = (e) => {
    //console.log("qty", e.target.value)
    setqty(e.target.value);
  };

  const handleCustomQuantityChange = (e, stock) => {
    const input = document.getElementById("quantity");
    const value = parseInt(input.value);
    if (value > stock) {
      input.value = "";
      return;
    }

    // if(e.target.value > stock) return;
    //console.log("stock", stock, e.target.value)
    if (e.target.value < 9) return;
    const customQuantity = e.target.value;
    setqty(customQuantity);
  };

  // useEffect(() => {
  //   if(single_product_detail.product?.stock){
  //     //console.log("insdie stock")
  //     const selectElement = document.getElementById('mySelect');
  //     const number = single_product_detail.product?.stock >9 ? 9 : single_product_detail.product?.stock; // The desired number of options

  //     for (let i = 1; i <= number; i++) {
  //       const option = document.createElement('option');
  //       option.value = i;
  //       if(i == 9){
  //         option.text = `Option ${i}+`;
  //       }else{
  //         option.text = `Option ${i}`;
  //       }
  //       //console.log("i", i)
  //       selectElement.appendChild(option);
  //     }
  //   }
  // }, [single_product_detail.product?.stock])

  const generateOptions = (number) => {
    const options = [];
    var number = number >= 9 ? 9 : number;

    for (let i = 1; i <= number; i++) {
      options.push(
        <option key={i} value={i}>
          {i === 9 ? i + "+" : i}
        </option>
      );
    }

    return options;
  };

  // const getVariations = () => {
  //     {
  //       single_product_detail.product?.custom_attrs.map(item => {
  //         return
  //         <>
  //           <span>{item.attribute_name}</span>

  //             {single_product_detail.product?.custom_values[item.attribute_slug].map(item1=> {
  //               return <span>{item.attribute_value}</span>
  //             })}

  //         </>
  //       })
  //     }
  // }

  // const handleUpdateQuantity = (itemId) => {
  //   const updatedItems = cartItems.map((item) =>
  //     item.cart_id === itemId ? { ...item } : item
  //   );
  //   setCartItems(updatedItems);
  //   // Perform any necessary update logic for the item with itemId
  // };

  const [combinationKey, setCombinationKey] = useState(null);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    //console.log("selected color", selectedColor)
    // && selectedColor
    if (selectedAttributes) {
      var combinationKey = "";
      if (selectedColor) {
        combinationKey += selectedColor + "-";
      }
      // `${selectedColor}-
      combinationKey += `${selectedAttributes
        .map((attr) => attr.value)
        .join("-")}`;
      setCombinationKey(combinationKey);
      setUpdated(!updated);
      //console.log("comb", combinationKey)
    }
  }, [selectedAttributes, selectedColor]);

  const handleAttributeChange = (attributeId, value) => {
    setSelectedAttributes((prevAttributes) => {
      // Find the attribute index
      const attributeIndex = prevAttributes.findIndex(
        (attr) => attr.id === attributeId
      );

      // Create a copy of the attributes array
      const updatedAttributes = [...prevAttributes];

      // If the attribute is already selected, update its value
      if (attributeIndex !== -1) {
        updatedAttributes[attributeIndex].value = value;
      } else {
        // If the attribute is not selected, add it to the end of the array
        updatedAttributes.push({ id: attributeId, value });
      }

      // Sort the attributes array based on the order of choice_attributes_combination
      updatedAttributes.sort((a, b) => {
        const aIndex =
          single_product_detail.product_variations.choice_attributes_combination.findIndex(
            (attr) => attr.id === a.id
          );
        const bIndex =
          single_product_detail.product_variations.choice_attributes_combination.findIndex(
            (attr) => attr.id === b.id
          );
        return aIndex - bIndex;
      });

      //console.log("updatedAttributes", updatedAttributes)

      return updatedAttributes;
    });

    // setSelectedAttributes((prevAttributes) => {
    //   const updatedAttributes = [...prevAttributes];
    //   const existingAttributeIndex = updatedAttributes.findIndex((attr) => attr.id === attributeId);

    //   if (existingAttributeIndex !== -1) {
    //     updatedAttributes[existingAttributeIndex].value = value;
    //   } else {
    //     updatedAttributes.push({ id: attributeId, value });
    //   }

    //   return updatedAttributes;
    // });
  };

  useEffect(() => {
    // Set initial selected attributes based on product data
    const initialAttributes =
      single_product_detail?.product_variations.choice_attributes_combination.map(
        (attr) => ({
          id: attr.id,
          value: attr.values[0],
        })
      );
    //console.log("intial attributes", initialAttributes, single_product_detail?.product_variations?.colors)
    if (single_product_detail?.product_variations?.colors) {
      var color = Object.entries(
        single_product_detail?.product_variations?.colors
      )[0];
      // //console.log("intial attributes color", color[1])
      if (color) {
        setSelectedColor(color[1]);
      }
    }
    setSelectedAttributes(initialAttributes);
  }, [single_product_detail?.product_variations]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (statuskey || status) {
      setIsVisible(true);

      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 2000); // 2000 milliseconds (2 seconds)

      return () => clearTimeout(timeout);
    }
  }, [statuskey, status]);

 

  return (
    <>
      <>
        {st.loaderStatus ? (
          <SiteLoader status={st.loaderStatus} />
        ) : (
          <>
            <main>
              {/* <ToastContainer position="bottom-center" pauseOnHover={false} hideProgressBar={true} pauseOnFocusLoss={false} closeOnClick={true} /> */}

              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                transition={Slide}
                hideProgressBar
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                theme="dark"
              />

              <div className="bg-light inner-breadcrumb">
                <div className="container">
                  <div className="breadcrumb-head">
                    <h3>
                      {single_product_detail?.product?.pro_name.length > 19
                        ? single_product_detail?.product?.pro_name.slice(
                            0,
                            19
                          ) + "..."
                        : single_product_detail?.product?.pro_name}
                    </h3>
                    <nav className="breadcrumb-wrap set-dec">
                      <ol className="breadcrumb">
                        {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                        <span
                          className="breadcrumb-item active order-span"
                          aria-current="page"
                          order_id={single_product_detail?.product?.id}
                          key={single_product_detail?.product?.id}
                          onClick={() =>
                            handleOrderClick(
                              single_product_detail?.product?.vendor_id,
                              single_product_detail.product?.shipping_profile
                                ?.id
                            )
                          }
                          style={{
                            cursor: "pointer",
                            "textDecoration": "none",
                          }}
                        >
                          <a>
                            <li
                              className="morefromvendor"
                              onClick={handleScrollToTop}
                            >
                              More from this vendor{" "}
                            </li>
                          </a>
                        </span>
                        {/* <li className="breadcrumb-item active" aria-current="page">{single_product_detail.product?.pro_name.length > 19 ? single_product_detail.product?.pro_name.slice(0, 19) + '...' : single_product_detail.product?.pro_name}</li> */}
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>

              <section className="single-wrapper section-wrapper">
                <div className="container ">
                  <div className="row product-detail-wrap">
                    <div className="col-md-5">
                      <div className="zoom_product">
                        <ImageGallery items={images} />
                      </div>

                      {/* <!-- end  --> */}
                    </div>
                    <div className="col-md-7">
                      <div className="product-detail">
                        <h3>{single_product_detail?.product?.pro_name}</h3>
                        <div className="review">
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          {/* <i className="fa fa-star-half-o" aria-hidden="true"></i> */}

                          <span className="grey-text">
                            ({single_product_detail?.rating_data?.total_rating}{" "}
                            Reviews)
                          </span>
                        </div>
                        <div className="priceTag">
                          <span>&#x24;</span>
                          {combinationKey
                            ? single_product_detail?.product_variations
                                ?.combinations[combinationKey]?.price * prodQty
                            : single_product_detail?.product?.sell_price * prodQty}
                        </div>
                        <div className="available">
                          {(
                            combinationKey
                              ? !single_product_detail?.product_variations
                                  ?.combinations[combinationKey]?.qty
                              : single_product_detail?.product?.stock === 0
                          ) ? (
                            <>
                              0 : <span className="text-red">No Stock</span>
                              &nbsp;&nbsp;
                              <span
                                className="order-span"
                                order_id={single_product_detail?.product?.id}
                                key={single_product_detail?.product?.id}
                                // onClick={() => handleOrderClick(single_product_detail?.product?.vendor_id)}
                                style={{ cursor: "pointer" }}
                              ></span>
                            </>
                          ) : (
                            <>
                              {single_product_detail?.product?.stock !== 0 ||
                                single_product_detail?.product_variations
                                  ?.combinations[combinationKey]?.qty}{" "}
                              {combinationKey
                                ? single_product_detail?.product_variations
                                    ?.combinations[combinationKey]?.qty
                                : single_product_detail?.product?.stock}{" "}
                              : <span className="text-green">In Stock</span>
                              &nbsp;&nbsp;
                              <span
                                className="order-span"
                                order_id={single_product_detail?.product?.id}
                                key={single_product_detail?.product?.id}
                                // onClick={() => handleOrderClick(single_product_detail?.product?.vendor_id)}
                                style={{ cursor: "pointer" }}
                              ></span>
                            </>
                          )}
                        </div>
                        <p>{single_product_detail?.product?.short_desc}</p>

              <ul className="select-color">
                <li><span>Color</span> <input type="radio" id="" name="color" /></li>
                <li><span>Color</span> <input type="radio" id="" name="color" /></li>
              </ul>

                        {single_product_detail?.product_variations && (
                          <div>
                            <table>
                              <tbody>
                                {/* Render color options */}
                                {single_product_detail?.product_variations
                                  ?.colors &&
                                  Object.keys(
                                    single_product_detail.product_variations
                                      .colors
                                  ).length > 0 && (
                                    <tr>
                                      <td
                                        className="button-column"
                                        style={{ paddingTop: "14%" }}
                                      >
                                        <div style={{ paddingTop: "25%" }}>
                                          <h4 className="variantFontSize">
                                            Colors
                                          </h4>
                                        </div>
                                      </td>
                                      <td style={{ paddingLeft: "22%" }}>
                                        <div className="d-flex">
                                          {Object.keys(
                                            single_product_detail
                                              .product_variations.colors
                                          ).map((colorCode) => {
                                            const colorName =
                                              single_product_detail
                                                .product_variations.colors[
                                                colorCode
                                              ];
                                            return (
                                              <div style={{ display: "flex" }}>
                                                <button
                                                  key={colorCode}
                                                  id="variantFontSizeId"
                                                  style={{
                                                    backgroundColor: colorCode,
                                                    display: "flex",
                                                    padding: "10px",
                                                  }}
                                                  onClick={() =>
                                                    handleColorChange(colorName)
                                                  }
                                                  className={
                                                    selectedColor === colorName
                                                      ? "color-selected"
                                                      : ""
                                                  }
                                                >
                                                  {/* {colorName} */}
                                                </button>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </td>
                                    </tr>
                                  )}

                                {/* Render attribute options */}
                                {single_product_detail?.product_variations && (
                                  <tr>
                                    {single_product_detail?.product_variations?.choice_attributes_combination.map(
                                      (attribute) => (
                                        <td
                                          className="button-column"
                                          style={{ paddingTop: "10%" }}
                                        >
                                          <div
                                            key={attribute.id}
                                            style={{ paddingTop: "28%" }}
                                          >
                                            <h4 className="variantFontSize">
                                              {attribute.name}
                                            </h4>
                                          </div>
                                        </td>
                                      )
                                    )}
                                    <td style={{ paddingLeft: "22%" }}>
                                      <div>
                                        {single_product_detail?.product_variations?.choice_attributes_combination.map(
                                          (attribute) => (
                                            <div
                                              key={attribute.id}
                                              style={{
                                                display: "flex",
                                                blockSize: "fit-content",
                                              }}
                                            >
                                              {attribute.values.map((value) => (
                                                <button
                                                  key={value}
                                                  // style={{ display: 'flex', 'alignItems':'center', 'padding': '10%', 'width': '100%', 'justifyContent': 'center' }}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "auto",
                                                    justifyContent: "center",
                                                  }}
                                                  id="variantFontSizeId"
                                                  onClick={() =>
                                                    handleAttributeChange(
                                                      attribute.id,
                                                      value
                                                    )
                                                  }
                                                  className={
                                                    selectedAttributes &&
                                                    selectedAttributes.some(
                                                      (attr) =>
                                                        attr.id ===
                                                          attribute.id &&
                                                        attr.value === value
                                                    )
                                                      ? "attribute-selected"
                                                      : ""
                                                  }
                                                >
                                                  {value}
                                                </button>
                                              ))}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}

                        <div className="priceDiv row col-md-8 justify-content-start">
                          <>
                            <div className="col-3">
                              <label className="customQntFont">Quantity:</label>
                            </div>
                            <div className="col-8 mt-2">
                              <div className="qty-style">
                                <button
                                  onClick={(e) =>
                                    setProdQty((prev) =>
                                      prev > 1 ? prev - 1 : prev
                                    )
                                  }
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <div>
                                  {prodQty}
                                </div>
                                <button
                                  onClick={(e) =>
                                    setProdQty((prev) => prev + 1)
                                  }
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </>
                        </div>
                        {/* <br/> */}

                        {/* <p>Selected Quantity: {qty}</p> */}

                        <hr />
                        <span id="v_msg"></span>

                        <div className="flex-action">
                          {/* <a href="#link" className="btn btn-lg btn-warning"> */}
                          <button
                            className="add-cart"
                            onClick={() =>
                              addToCart(single_product_detail.product?.pro_slug)
                            }
                            // disabled={isAddToCartDisabled}
                            disabled={
                              combinationKey
                                ? (single_product_detail?.product_variations
                                    ?.combinations[combinationKey]?.display ===
                                  "on"
                                    ? false
                                    : true) ||
                                  !single_product_detail?.product_variations
                                    ?.combinations[combinationKey]?.qty
                                : !single_product_detail?.product?.stock ||
                                  loader
                            }
                          >
                            {loader ? (
                              "Adding to cart..."
                            ) : (
                              <span>
                                <i
                                  className="fa fa-shopping-cart"
                                  aria-hidden="true"
                                ></i>
                                Add to Cart
                              </span>
                            )}
                          </button>
         
                          {/* </a> */}
                          {/* <a href="/userwishlist" className="wishlist round-btn"> */}
                          <i
                            className={
                              single_product_detail?.wish_listed
                                ? "fa fa-heart hearticoncolor selected"
                                : "fa fa-heart"
                            }
                            aria-hidden="true"
                            wish-class="true"
                            id="heartIcon"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              addToWishList(single_product_detail.product?.id)
                            }
                          ></i>

<div className="go-social">
<FacebookShareButton
                            url={
                              "https://mangtum.in/shop/curated-painting/null/painting-pradeesh-raman-untitled-11-36-x-36-inches-acrylic-on-canvas"
                            }
                            hashtag="Mangtum.in"
                            quote="Best Seller in the town"
                          >
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>
                          <WhatsappShareButton
                            url={
                              "https://mangtum.in/shop/curated-painting/null/painting-pradeesh-raman-untitled-11-36-x-36-inches-acrylic-on-canvas"
                            }
                            hashtag="Mangtum.in"
                            quote="Best Seller in the town"
                          >
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                          <LinkedinShareButton
                            url={
                              "https://mangtum.in/shop/curated-painting/null/painting-pradeesh-raman-untitled-11-36-x-36-inches-acrylic-on-canvas"
                            }
                            hashtag="Mangtum.in"
                            quote="Best Seller in the town"
                          >
                            <LinkedinIcon size={30} round={true} />
                          </LinkedinShareButton>
                          <TwitterShareButton
                            url={
                              "https://mangtum.in/shop/curated-painting/null/painting-pradeesh-raman-untitled-11-36-x-36-inches-acrylic-on-canvas"
                            }
                            hashtag="Mangtum.in"
                            quote="Best Seller in the town"
                          >
                            <TwitterIcon size={30} round={true} />
                          </TwitterShareButton></div>     
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr />
              {/* <!-- description  --> */}
              <section className="section-wrapper description">
                <div className="container">
                  <div className="desc-tab">
                    <ul className="nav nav-tabs" id="desTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "description" ? "active" : ""
                          }`}
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="description-tab-pane"
                          aria-selected={activeTab === "description"}
                          onClick={() => handleTabChange("description")}
                        >
                          <i className="ri-dashboard-line" /> Description
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "store" ? "active" : ""
                          }`}
                          id="store-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#store"
                          type="button"
                          role="tab"
                          aria-controls="store"
                          aria-selected={activeTab === "store"}
                          onClick={() => handleTabChange("store")}
                        >
                          <i className="ri-dashboard-line" /> Store Info
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            activeTab === "shipping" ? "active" : ""
                          }`}
                          id="shipping-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#shipping"
                          type="button"
                          role="tab"
                          aria-controls="shipping"
                          aria-selected={activeTab === "shipping"}
                          onClick={() => handleTabChange("shipping")}
                        >
                          <i className="ri-dashboard-line" /> Shipping
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content accordion" id="desTabContent">
                      <div
                        className={`tab-pane fade accordion-item ${
                          activeTab === "description" ? "active show" : ""
                        }`}
                        id="description-tab-pane"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                        tabIndex={0}
                      >
                        <div
                          id="description"
                          className="accordion-collapse collapse show d-lg-block"
                          aria-labelledby="description"
                          data-bs-parent="#desTabContent"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="des-img">
                                  <img
                                    src={
                                      single_product_detail?.product?.main_image
                                    }
                                    className="img-fluid"
                                    title=""
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="description-content">
                                  <h3>
                                    {single_product_detail?.product?.pro_name}
                                  </h3>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        single_product_detail?.product
                                          ?.description,
                                    }}
                                  ></p>
                                </div>
                              </div>
                              {/* <div className="col-md-4 hide">
                          <div className="description-content">
                            <h3>Vendor Details </h3>

                            <ul className="list-unstyled">
                              <li>{single_product_detail.product?.vendor?.full_name}</li>
                              <li>{single_product_detail.product?.vendor?.store_name}</li>
                            </ul>
                          </div>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* NEW three tabs added start */}
                      <div
                        className={`tab-pane fade accordion-item ${
                          activeTab === "store" ? "active show" : ""
                        }`}
                        id="store"
                        role="tabpanel"
                        aria-labelledby="store-tab"
                      >
                        <div
                          id="store"
                          className="accordion-collapse collapse show  d-lg-block"
                          aria-labelledby="store"
                          data-bs-parent="#desTabContent"
                        >
                          <div className="accordion-body">
                            <div className="description-content">
                              {/* NEW three tabs added start */}
                              <div className="container">
                                <div className="desc-tab">
                                  <ul
                                    className="nav nav-tabs tm-tab"
                                    id="desTabnew"
                                    role="tablist"
                                  >
                                    {single_product_detail?.product?.vendor
                                      ?.store_name && (
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <button
                                          className={`nav-link ${
                                            activeTabNew === "vp"
                                              ? "active"
                                              : ""
                                          }`}
                                          aria-selected={activeTabNew === "vp"}
                                          onClick={() =>
                                            handleTabChangeNew("vp")
                                          }
                                        >
                                          Vendor Information
                                        </button>
                                      </li>
                                    )}
                                    {store_info_tab?.vendor
                                      ?.shipping_policy && (
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <button
                                          className={`nav-link ${
                                            activeTabNew === "sp"
                                              ? "active"
                                              : ""
                                          }`}
                                          aria-selected={activeTabNew === "sp"}
                                          onClick={() =>
                                            handleTabChangeNew("sp")
                                          }
                                        >
                                          Shipping Policy
                                        </button>
                                      </li>
                                    )}
                                    {store_info_tab?.vendor?.return_policy && (
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <button
                                          className={`nav-link ${
                                            activeTabNew === "rt"
                                              ? "active"
                                              : ""
                                          }`}
                                          aria-selected={activeTabNew === "rt"}
                                          onClick={() =>
                                            handleTabChangeNew("rt")
                                          }
                                        >
                                          Return Policy
                                        </button>
                                      </li>
                                    )}
                                    {store_info_tab?.vendor
                                      ?.terms_conditions && (
                                      <li
                                        className="nav-item"
                                        role="presentation"
                                      >
                                        <button
                                          className={`nav-link ${
                                            activeTabNew === "tc"
                                              ? "active"
                                              : ""
                                          }`}
                                          aria-selected={activeTabNew === "tc"}
                                          onClick={() =>
                                            handleTabChangeNew("tc")
                                          }
                                        >
                                          Terms & Conditions
                                        </button>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                                <div
                                  id="vp"
                                  className={`tab-pane fade accordion-item text-alignment ${
                                    activeTabNew === "vp"
                                      ? "active show"
                                      : "hide"
                                  }`}
                                >
                                  <div
                                    id="vp"
                                    className="accordion-collapse collapse show  d-lg-block"
                                    aria-labelledby="vp "
                                    data-bs-parent="#desTabContent"
                                  >
                                    <ul className="list-unstyled">
                                      <li>
                                        <strong>
                                          {
                                            single_product_detail?.product
                                              ?.vendor?.full_name
                                          }
                                        </strong>
                                      </li>
                                      <li>
                                        <strong>
                                          {
                                            single_product_detail?.product
                                              ?.vendor?.store_name
                                          }
                                        </strong>
                                      </li>
                                      <li>{store_info_tab?.vendor?.bio}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  id="sp"
                                  className={`tab-pane fade accordion-item text-alignment manage-text ${
                                    activeTabNew === "sp"
                                      ? "active show"
                                      : "hide"
                                  }`}
                                >
                                  <div
                                    id="sp"
                                    className="accordion-collapse collapse show  d-lg-block"
                                    aria-labelledby="sp "
                                    data-bs-parent="#desTabContent"
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          store_info_tab?.vendor
                                            ?.shipping_policy,
                                      }}
                                    ></p>
                                  </div>
                                </div>

                                <div
                                  id="rt"
                                  className={`tab-pane fade accordion-item text-alignment manage-text ${
                                    activeTabNew === "rt"
                                      ? "active show"
                                      : "hide"
                                  }`}
                                >
                                  <div
                                    id="rt"
                                    className="accordion-collapse collapse show  d-lg-block"
                                    aria-labelledby="rt "
                                    data-bs-parent="#desTabContent"
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          store_info_tab?.vendor?.return_policy,
                                      }}
                                    ></p>
                                  </div>
                                </div>

                                <div
                                  id="tc"
                                  className={`tab-pane fade accordion-item text-alignment manage-text ${
                                    activeTabNew === "tc"
                                      ? "active show"
                                      : "hide"
                                  }`}
                                >
                                  <div
                                    id="tc"
                                    className="accordion-collapse collapse show  d-lg-block"
                                    aria-labelledby="tc "
                                    data-bs-parent="#desTabContent"
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          store_info_tab?.vendor
                                            ?.terms_conditions,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                              {/* NEW three tabs added end */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* NEW three tabs added end */}

                      <div
                        className={`tab-pane fade accordion-item ${
                          activeTab === "shipping" ? "active show" : ""
                        }`}
                        id="shipping"
                        role="tabpanel"
                        aria-labelledby="shipping-tab"
                      >
                        <div
                          id="shipping"
                          className="accordion-collapse collapse show  d-lg-block"
                          aria-labelledby="shipping "
                          data-bs-parent="#desTabContent"
                        >
                          <div className="accordion-body">
                            <div className="description-content">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="description-content">
                                    <ul className="list-unstyled">
                                      {/* <li>Shipping profile Name: <span className="color-class">{store_info_tab?.shipping_info?.shipping_profile_name}</span> </li> */}
                                      {store_info_tab?.address
                                        ?.country_name && (
                                        <li>
                                          Shipping Origin Country:{" "}
                                          <span className="color-class">
                                            {
                                              store_info_tab?.address
                                                ?.country_name
                                            }
                                          </span>{" "}
                                        </li>
                                      )}
                                      {store_info_tab?.shipping_info
                                        ?.processing_time && (
                                        <li>
                                          Processing Time:{" "}
                                          <span className="color-class">
                                            {
                                              store_info_tab?.shipping_info
                                                ?.processing_time
                                            }{" "}
                                            Days
                                          </span>{" "}
                                        </li>
                                      )}
                                      {store_info_tab?.shipping_info
                                        ?.additional_item_fee && (
                                        <li>
                                          Additional Item Fee:{" "}
                                          <span className="color-class">
                                            $
                                            {
                                              store_info_tab?.shipping_info
                                                ?.additional_item_fee
                                            }
                                          </span>{" "}
                                        </li>
                                      )}
                                      {store_info_tab?.shipping_info
                                        ?.handling_fee && (
                                        <li>
                                          Standard Shipping Fee:{" "}
                                          <span className="color-class">
                                            $
                                            {
                                              store_info_tab?.shipping_info
                                                ?.handling_fee
                                            }
                                          </span>{" "}
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="description-content">
                                    <ul className="list-unstyled">
                                      {store_info_tab?.shipping_info
                                        ?.expedited_fee && (
                                        <li>
                                          Expedited Fee:{" "}
                                          <span className="color-class">
                                            $
                                            {
                                              store_info_tab?.shipping_info
                                                ?.expedited_fee
                                            }{" "}
                                          </span>
                                        </li>
                                      )}
                                      {store_info_tab?.shipping_info
                                        ?.additional_expedited_fee && (
                                        <li>
                                          Additional Expedited Fee:{" "}
                                          <span className="color-class">
                                            $
                                            {
                                              store_info_tab?.shipping_info
                                                ?.additional_expedited_fee
                                            }
                                          </span>{" "}
                                        </li>
                                      )}
                                      {store_info_tab?.shipping_info
                                        ?.processing_fee && (
                                        <li>
                                          Processing Fee:{" "}
                                          <span className="color-class">
                                            $
                                            {
                                              store_info_tab?.shipping_info
                                                ?.processing_fee
                                            }
                                          </span>{" "}
                                        </li>
                                      )}
                                      {store_info_tab?.shipping_info
                                        ?.gift_packing_fee && (
                                        <li>
                                          Gift Packing Fee:{" "}
                                          <span className="color-class">
                                            $
                                            {
                                              store_info_tab?.shipping_info
                                                ?.gift_packing_fee
                                            }
                                          </span>{" "}
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className={`section-wrapper featured bg-light ${
                  related_prodcts?.length === 0 ? "hide" : ""
                }`}
              >
                <div className="container footerpadding">
                  <div
                    className={`wrapper-header text-center mb-4 ${
                      related_prodcts?.product?.length < 0 ? "hide" : ""
                    }`}
                  >
                    {/* <h4 className="grey-text wow animated fadeInUp">More from this Seller</h4> */}
                    <h3 className="wow animated fadeInUp" data-wow-delay="0.3s">
                      Related Items
                    </h3>
                    <p className="wow animated fadeInUp"></p>
                  </div>
                  {/* <!-- product  --> */}

                  <div className="flex-product flexgrow">
                    {related_prodcts.product?.map((rp, index) => {
                      // //console.log("rp", rp);
                      return (
                        <div className="item" key={index}>
                          {/* <a href = {`/shop/${rp.slug}/${rp.sub_cate_slug}/${rp.pro_slug}`}> */}
                          <a
                            href={`/shop/${rp.slug}/${rp.sub_cate_slug}/${rp.pro_slug}`}
                          >
                            {/* <Link to={`/shop/${rp.slug}/${rp.sub_cate_slug}/${rp.pro_slug}`}> */}
                            <div className="product-row wow animated fadeInUp">
                              <div className="product-cover">
                                <img
                                  src={rp.main_image}
                                  className="img-fluid"
                                  title=""
                                  alt=""
                                />
                                <div className="wishlist-icon">
                                  <i
                                    className="fa fa-heart "
                                    aria-hidden="true"
                                    wish-class="true"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => addToWishList(rp.id)}
                                  ></i>
                                </div>
                              </div>
                              {/* <!-- product-cont  --> */}
                              <div className="product-content p-3">
                                <h4>
                                  {rp.pro_name.length > 15
                                    ? rp.pro_name.slice(0, 15) + "..."
                                    : rp.pro_name}{" "}
                                </h4>
                                <div className="title grey-text mb-2">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        rp.short_desc.length > 15
                                          ? rp.short_desc.slice(0, 15) + "..."
                                          : rp.short_desc,
                                    }}
                                  ></p>
                                </div>
                                <div className="price">
                                  <div className="grey-text">
                                    <span className="text-warning">
                                      ${rp.sell_price}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </a> */}
                          </a>
                          {/* </Link> */}
                        </div>
                      );
                    })}
                  </div>
                  {/* <!-- view all  --> */}
                  {/* <div className="btn-center text-center mt-4"><a href="#link" className="btn text-uppercase btn-warning btn-lg">View More Products</a></div> */}
                </div>
              </section>

              <hr />
            </main>
          </>
        )}
      </>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
    </>
  );
};

export default Singleproduct;
