import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { getHomepage, get_meta } from "../../store/home";
import { Helmet } from "react-helmet";
import SiteLoader from "../../SiteLoader";
import useLoader from "../../components/useLoader";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../components/img/default.png";
import Companies from "./components/Companies";
import { images } from "../../utils/images";

const Home = () => {
  const dispatch = useDispatch();
  const isLoading = useLoader();
  const state = useSelector((state) => state.home);
  const homerec = useSelector((state) => state.home.home_array);

  // BANNER

  useEffect(() => {
    dispatch(getHomepage());
  }, []);

  const Banner = homerec?.banner;

  // EDITORPIC

  const homepageData = useSelector((state) => state.home.homepage_data);

  const cate1 = "Beads"; //useSelector(state => state.home.editorpics_category_one);
  const cate2 = "Fashion Jewellery"; //useSelector(state => state.home.editorpics_category_two);
  const cate3 = "Fine Jwellery"; //useSelector(state => state.home.editorpics_category_three);
  const cate4 = "Gemstones"; //useSelector(state => state.home.editorpics_category_four);
  //'{"foo": 01}'

  {
    /* <!-- featured products  --> */
  }
  {
    /* BESTSELLER PRODUCT */
  }
  const Bestsellers = homerec?.featured_products;
  // SERVICES
  // FRIENDLIST MARCKETPLACE
  const Services = homerec?.services;
  // POPULAR PRODUCT
  const Populars = homerec?.popular_products;
  const abouts = homerec?.about;
  // META TAGS START
  useEffect(() => {
    dispatch(get_meta("home-page"));
  }, ["home-page"]);
  const metaTags = state.allmeta;
  // const metaTags = useSelector((state) => state.contact.data.meta_data);
  const siteUrl = window.location.href;
  // META TAGS END
  let imgurL = "";
  if (
    homerec &&
    homerec.services &&
    homerec.services["0"] &&
    homerec.services["0"].image
  ) {
    imgurL = homerec.services["0"].image;
  } else {
  }
  // scroll top functionality start
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // scroll top functionality end
  const navigate = useNavigate();
  const openAbout = () => {
    navigate("/about");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {state.loaderStatus ? (
        <SiteLoader status={state.loaderStatus} />
      ) : (
        <>
          <div className="container" style={{ maxWidth: "100%" }}>
            <Carousel>
              {Banner?.map((imgban, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      src={imgban.image}
                      className="img-fluid"
                      title=""
                      alt=""
                    />
                    <div className="swiper-caption">
                      <div className="caption-content">
                        <h1 className="slide-head">
                          {homerec?.welcome_text["0"]?.heading}
                        </h1>
                        <p>{homerec?.welcome_text["0"]?.content}</p>
                        {/* <Link to="#link" className="btn btn-warning btn-lg">Shop Now</Link> */}
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
          {/* <!-- editors pic  --> */}
          {/* {state.loaderStatus ? <SiteLoader status={state.loaderStatus} /> :  */}
          <>
            <div className="container">
              <section className="section-wrapper editorsPic">
                <div className="container">
                  <div className="row">
                    <div className="flex-between-header col-md-12 pt-0">
                      <div className="flex-between w-100 view-container">
                        <div>
                          <h3 className="wow animated fadeInUp">
                            Editor's Pic
                          </h3>
                          <span className="wow-text">
                            Problems trying to resolve the conflict between{" "}
                          </span>
                        </div>
                        <div className="view-all">
                          <Link to={"#"}>
                            View All
                            <i className="fa fa-arrow-right"></i>
                          </Link>
                        </div>
                        <Helmet>
                          <meta charSet="utf-8" />
                          <title>{metaTags?.meta_data?.meta_title}</title>
                          <meta
                            name="description"
                            content={metaTags?.meta_data?.meta_description}
                          />
                          <meta property="og:type" content="website" />
                          <meta property="og:url" content={siteUrl} />
                          <meta
                            property="og:title"
                            content={metaTags?.meta_data?.og_title}
                          />
                          <meta
                            property="og:description"
                            content={metaTags?.meta_data?.og_description}
                          />
                          <meta
                            property="og:image"
                            content={images["logo.png"]}
                          />
                        </Helmet>
                      </div>
                    </div>
                    <div className="row gutter">
                      <div className="col-md-6 col-sm-6">
                        <Link
                          to="/product-by-category/beads"
                          className="flex-colmn mx-100 wow animated fadeInUp"
                        >
                          <img
                            src={images["img1.jpg"]}
                            className=""
                            title=""
                            alt={cate1}
                          />
                          <p className="overlay-heading">{cate1}</p>
                        </Link>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <Link
                          to="/product-by-category/fashion-jewellery"
                          className="flex-colmn wow animated fadeInUp"
                        >
                          <img
                            src={images["img2.jpg"]}
                            className=""
                            title=""
                            alt=""
                          />
                          <p className="overlay-heading">{cate2}</p>
                        </Link>
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <div className="row sm-col">
                          <div className="col-sm-6 col-md-12">
                            <Link
                              to="/product-by-category/fine-jewellery"
                              className="flexrow wow animated fadeInUp rm-2"
                            >
                              <img
                                src={images["img3.png"]}
                                className=""
                                title=""
                                alt=""
                              />
                              <p className="overlay-heading">{cate3}</p>
                            </Link>
                          </div>
                          <div className="col-sm-6 col-md-12">
                            <Link
                              to="/product-by-category/gemstones"
                              className="flexrow wow animated fadeInUp"
                            >
                              <img
                                src={images["img4.jpg"]}
                                className=""
                                title=""
                                alt=""
                              />
                              <p className="overlay-heading">{cate4}</p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* //  end editors pic */}

            {/* BESTSELLER PRODUCT  */}
            <div>
              <section className="section-wrapper featured bg-light">
                <div className="container">
                  <div className="wrapper-header text-center mb-4">
                    <h4 className="grey-text wow animated fadeInUp">
                      Featured Products
                    </h4>
                    <h3 className="wow animated fadeInUp" data-wow-delay="0.3s">
                      Bestseller Products
                    </h3>
                    <p className="wow animated fadeInUp product-text">
                      Problems trying to resolve the conflict between{" "}
                    </p>
                  </div>

                  {/* <!-- product  --> */}
                  <div className="flex-product flexgrow">
                    {Bestsellers?.map((Bestseller, index) => {
                      return (
                        <Link
                          to={`/shop/${Bestseller.category_slug}/${Bestseller.subcategory_slug}/${Bestseller.pro_slug}`}
                          onClick={handleScrollToTop}
                          className="item"
                          key={index}
                        >
                          <div className="product-row wow animated fadeInUp">
                            <div className="product-cover">
                              <img
                                src={Bestseller.main_image}
                                className="img-fluid"
                                title=""
                                alt=""
                                onError={(e) => (e.target.src = defaultImg)}
                              />
                            </div>
                            <div className="product-content p-3">
                              <h4>
                                {Bestseller.product_name.length > 15
                                  ? Bestseller.product_name.slice(0, 15) + "..."
                                  : Bestseller.product_name}
                              </h4>
                              <div
                                className="title grey-text mb-2"
                                style={{ fontSize: "13px" }}
                              >
                                {Bestseller.short_desc.length > 16
                                  ? Bestseller.short_desc.slice(0, 16) + "..."
                                  : Bestseller.short_desc}
                              </div>
                              <div className="price">
                                <div className="grey-text">
                                  {Bestseller && Bestseller.regular_price ? (
                                    <>
                                      {/* <del>${Bestseller.regular_price}</del> */}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <span className="text-warning">
                                    ${Bestseller && Bestseller.sell_price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>

                  <button className="view-more">View More Products</button>
                </div>
              </section>
              {/* <!-- end  --> */}
            </div>

            {/* ABOUT */}
            <div>
              <>
                <section
                  className="section-wrapper about-wrapper"
                  style={{
                    backgroundImage: `url(${homerec?.about?.banner_image})`,
                  }}
                >
                  <div className="white-lay">
                    <div className="container">
                      <div className="row">
                        <div className="head-wrap-about col-md-6 about-section">
                          <h4>{homerec?.about?.heading}</h4>
                          <h3>{homerec?.about?.name}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: homerec?.about?.homecontent,
                            }}
                          ></p>
                          {/* <Link to="/about" className="btn btn-sm btn-warning" onClick={handleScrollToTop}>Know More</Link> */}
                          <button
                            className="shop-now"
                            onClick={() => openAbout()}
                          >
                            SHOP NOW
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            </div>

            {/* FRIENDLIST MARKETPLACE */}
            {/* SERVICES */}

            <>
              <section className="section-wrapper marketplace">
                {Services?.map((services_data, index) => {
                  return (
                    <div className="services" key={index}>
                      <div
                        className="respon"
                        style={{
                          backgroundImage: `url(${images["marketplace1.png"]}), url(${images["marketplace2.png"]})`,
                        }}
                      >
                        <div className="marketplace-wrap container">
                          <h1 className="mb-2">{services_data.heading}</h1>
                          <h4 className="content mb-2">
                            {services_data.content.length > 190
                              ? services_data.content.slice(0, 240) + "..."
                              : services_data.content}
                            ...
                          </h4>
                          <a
                            href="https://mangtum.in/admin/vendor/login"
                            target="_blank"
                            className="join-now"
                          >
                            Join Now
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </section>
            </>

            {/* <!-- POPULAR PRODUCT --> */}
            <div>
              <section className="section-wrapper featured bg-light">
                <div className="container">
                  <div className="wrapper-header text-center mb-4">
                    <h3
                      className="wow animated fadeInUp popular-prod"
                      data-wow-delay="0.3s"
                    >
                      Popular Products
                    </h3>
                    <p className="wow animated fadeInUp popular-text">
                      Problems trying to resolve the conflict between
                    </p>
                  </div>

                  {/* <!-- product  --> */}
                  {/* <!-- Swiper --> */}
                  <Swiper
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      600: {
                        slidesPerView: 3,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      // when window width is >= 768p
                      // when window width is >= 992px
                      992: {
                        slidesPerView: 5,
                      },
                    }}
                    spaceBetween={15}
                    slidesPerView={5}
                    freeMode={true}
                    modules={[Autoplay]}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    speed={1000}
                    watchSlidesProgress={true}
                  >
                    {Populars?.map((Popular, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="swiper popularProduct">
                            <div className="swiper-wrapper">
                              <div className="swiper-slide">
                                <Link
                                  to={`/shop/${Popular.category_slug}/${Popular.subcategory_slug}/${Popular.pro_slug}`}
                                  data-tip="Quick View"
                                  onClick={handleScrollToTop}
                                  className="item"
                                >
                                  <div className="product-row wow animated fadeInUp">
                                    <div className="product-cover">
                                      <div>
                                        <img
                                          src={Popular.main_image}
                                          className="img-fluid img-h card-img-top"
                                          title=""
                                          alt=""
                                          onError={(e) =>
                                            (e.target.src = defaultImg)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div className="product-content">
                                        <h4>
                                          {" "}
                                          {Popular.product_name.length > 20
                                            ? Popular.product_name.slice(
                                                0,
                                                20
                                              ) + "..."
                                            : Popular.product_name}
                                        </h4>
                                        <div className="title">
                                          {Popular.short_desc.length > 20
                                            ? Popular.short_desc.slice(0, 20) +
                                              "..."
                                            : Popular.short_desc}
                                        </div>
                                        <div className="price">
                                          {/* <del>${Popular.regular_price}</del> */}
                                          <span>${Popular.sell_price}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </section>

              <Companies />
            </div>
          </>
        </>
      )}
    </>
  );
};

export default Home;
