
import React, { useReducer, useRef } from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
// import { Link } from 'react-router-dom';
// import { loginUser, signupUser, fbLogin, googleLogin, userActions,fetchSocialCredential } from '../store/user';
import { useDispatch, useSelector } from 'react-redux';

import { updateFieldContact, getContact, updateSetAlreadyRegister } from "../store/auth";
// import { object } from 'yup';
import { Link, useNavigate } from 'react-router-dom'
import { get_meta } from '../store/home';
import { Helmet } from 'react-helmet';
import SiteLogo from "../components/img/logo.png";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  //document.getElementById('email-is-register')?.classList.add("hide");
  const dispatch = useDispatch();

  const intialValues = { name: "", email: "", mobile: "", query: "" }

  const navigate = useNavigate()

  const form_fields = useSelector(state => state.auth.contact_field)
  const state = useSelector(state => state.auth)

  useEffect(() => {
  }, [form_fields])

  const country_code = useRef();

  const [errors, setErrors] = useState(false)
  const handleChangeUpdate = (e) => {
    const { name, value } = e.target;
    var input = { field: name, value: value }
    dispatch(updateFieldContact(input))
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    var form_fields_new = Object.assign({}, form_fields) 
    // country_code.current.value +
    form_fields_new.mobile =  form_fields.mobile
    if (validate(form_fields_new)) {
      // return;
      dispatch(getContact(form_fields_new))
      toast("Your details submitted successfully. We will back to you shortly.");
      document.getElementById("myform").reset();
    }
  }

  const validate = (values) => {
    const error = {};
    const regex = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
    const pass_regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (!values.name) {
      error.name = "Name is required!"
    }

    if (!values.email) {
      error.email = "Email is required!"
    }
    else if (!regex.test(values.email)) {
      error.email = "Plese enter the correct format of the email."
    }
    if (!values.mobile) {
      error.mobile = "Phone Number is required!"
    }
    if (!values.query) {
      error.query = "Message is required!"
    }

    setErrors(error);
    return Object.values(error).length > 0 ? false : true;
  }

  // META TAGS START
  const homestate = useSelector(state => state.home)
  useEffect(() => {
    dispatch(get_meta('contact'))
  }, ['contact'])
  const metaTags = homestate.allmeta;
  const siteUrl = window.location.href;
  // META TAGS END

  return (
    <div>
      <main>
      <div className="bg-light inner-breadcrumb">
    <div className="container">
      <div className="breadcrumb-head">
        <h3>Contact Us</h3>
        <nav className="breadcrumb-wrap">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page" style={{'fontSize':'12px', 'paddingTop':'3px'}}>Contact Us</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
      
      <section className="single-wrapper section-wrapper">
        <div className="container5 contactbg">
          {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}
          <div className="row pt-2 pb-5 justify-content-center">
            <div className="col-md-6 login-wrap">
              {/* <h3 className="text-center">Contact Us</h3> */}
              <Helmet>
                 <meta charSet="utf-8" />
                 <title>{metaTags?.meta_data?.meta_title}</title>
                 <meta name="description" content={metaTags?.meta_data?.meta_description}/>
                 <meta property="og:type" content="website" />
                 <meta property="og:url" content={siteUrl} />
                 <meta property="og:title" content={metaTags?.meta_data?.og_title} />
                 <meta property="og:description" content={metaTags?.meta_data?.og_description}/>
                 <meta property="og:image" content={SiteLogo}  />
              </Helmet>

              <form onSubmit={handelSubmit} id="myform">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" name="name" onChange={handleChangeUpdate} className="form-control" placeholder="Enter your name" />
                </div>
                <p className="register_error">{errors.name}</p>

                <div className="form-group">
                  <label>Email Address</label>
                  <input type="text" name="email" onChange={handleChangeUpdate} className="form-control" placeholder="Enter a valid email address" />
                </div>
                <p className="register_error">{errors.email}</p>
                
                <div className="form-group">
                  <label>Phone Number</label>
                  <div className='row'>
                  {/* <div className='col-md-2'>
                    <select name="country_code" ref={country_code} className="form-control" style={{'width':'65px'}}>
                        <option value="+91">(+91)India</option>
                        <option value="+1">(+1)US</option>
                        <option value="+1">(+1)CA</option>
                        <option value="+82">(+82)South Korea</option>
                        <option value="+27">(+27)South Africa</option>
                        <option value="+61">(+61)Australia</option>
                        <option value="+86">(+86)China</option>
                        <option value="+33">(+33)France</option>
                        <option value="+81">(+81)Japan</option>
                    </select>&nbsp;
                  </div> */}
                  <div className='col-md-12'>
                  <input
                      type="text"
                      name="mobile"
                      className="form-control"
                      minLength={10}
                      placeholder='Enter your phone number'
                      maxLength={10}
                      onChange={handleChangeUpdate}
                      required
                  />
                  </div>
                  {/* <input type="text" name="mobile" onChange={handleChangeUpdate} className="form-control" placeholder="Enter your phone number" /> */}
                </div>
                </div>
                <p className="register_error">{errors.mobile}</p>
                <div className="mb-3">
                  <label for="exampleFormControlTextarea1" className="form-label">Message</label>
                  <textarea className="form-control" name="query" id="exampleFormControlTextarea1" placeholder="Enter your message" rows="3" onChange={handleChangeUpdate}></textarea>
                  <p className="register_error">{errors.query}</p>
                </div>
                <button className="btn btn-md btn-warning w-100" id="reg_btn">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      </main>

      {/* <section className="section-wrapper bg-light">
        <div className="container">
          <div className="row pt-4 pb-4">
            <div className="col-md-6">
              <div className="flex-center">
                <div className="contact-center-box">
                  <h1>Contact Us</h1>
                  <div className="con_txt">
                    Problems trying to resolve the conflict between the two major realms of Classical physics: Newtonian mechanics.
                  </div>
                  <a href="/contact" className="btn btn-lg btn-warning">Contact Us</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="flexAddress">
                <div className="addressBox">
                  <h4>Bhubaneswar</h4>
                  <h5>1901 Thorn ridge Cir.</h5>
                  <p>
                    75000 Paris <br />
                    Phone ; +451 215 215 <br />
                    Fax : +451 215 215
                  </p>
                </div>
                <div className="addressBox">
                  <h4>Sambalpur</h4>
                  <h5>1901 Thorn ridge Cir.</h5>
                  <p>
                    75000 Paris <br />
                    Phone ; +451 215 215 <br />
                    Fax : +451 215 215
                  </p>
                </div>
                <div className="addressBox">
                  <h4>Cuttack</h4>
                  <h5>1901 Thorn ridge Cir.</h5>
                  <p>
                    75000 Paris <br />
                    Phone ; +451 215 215 <br />
                    Fax : +451 215 215
                  </p>
                </div>
                <div className="addressBox">
                  <h4>Burla</h4>
                  <h5>1901 Thorn ridge Cir.</h5>
                  <p>
                    75000 Paris <br />
                    Phone ; +451 215 215 <br />
                    Fax : +451 215 215
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
    
  );
}

export default Contact;