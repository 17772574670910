import "../../App.css";
import "spiketip-tooltip/spiketip.min.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getSearch_products,
  setStatusFalse,
  getMycart,
  getTopbar,
} from "../../store/home";
import { getProductByCategory } from "../../store/product";
import MobileHeader from "./MobileHeader";
import { images } from "../../utils/images";
import AutoComplete from "./../General/AutoComplete"

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCategories());
  }, []);
  const headerCategories = useSelector((state) => state.home.headerCategories);
  const statehome = useSelector((state) => state.home);

  //  LOGOUT FUNCTIONALITY
  const handleLogout = () => {
    localStorage.removeItem("userDetails");
    window.location.reload();
  };
  //  Profile FUNCTIONALITY

  const handleSearch = () => {
    dispatch(getSearch_products(inputValue.value, 1));
    navigate("/product-search?search=" + inputValue.value);
  };

  const [inputValue, setInputValue] = useState({clicked:false, value:""});
  const [showModal, setShowModal] = useState(false);
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      let page = 1;
      dispatch(getSearch_products(inputValue.value, page));
      let search_i = document.getElementById("search_item").value;
      search_i = search_i.replace(/\s+/g, "-").toLowerCase();
      setInputValue({clicked:false, value:""})
      setShowModal(false)
      navigate("/product-search?search=" + search_i);
    }
  }

  useEffect(() => {
    if (statehome.searchstatus) {
      let search_i = document.getElementById("search_item").value;
      search_i = search_i.replace(/\s+/g, "-").toLowerCase();

      navigate("/product-search?search=" + search_i);
    } else {
      dispatch(setStatusFalse());
    }
  }, [statehome.searchstatus]);

  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);

  var user_id = data?.ID;
  useEffect(() => {
    dispatch(getMycart({ user_id }));
  }, []);
  const totalCart = useSelector((state) => state.home.cartrecords);

  // NAVIGATION MENU START
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubcategory = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  // NAVIGATION MENU START

  useEffect(() => {
    dispatch(getTopbar());
  }, []);

  const onProductCategoryClick = (category) => {
    dispatch(getProductByCategory({ catslug: category }));
  };

  const Topbar = useSelector((state) => state.home.Topbar);

  
  return (
    <div className="App">
      {/* <SiteLoader status={state.loaderStatus}/> */}
      <div className="top-header">
        <div className="container">
          <div className="flex-header">
            <div className="newsflash">
              <p dangerouslySetInnerHTML={{ __html: Topbar.data }}></p>
            </div>
            <div className="clgrid">
              {/* <i className="ri-customer-service-2-line"></i> Toll Free : <b>{Topbar.contact}</b> */}
              {Topbar.contact}
            </div>
          </div>
        </div>
      </div>

      <div className="navbar_header">
        <div className="container">
          <div className="flex-header my-3">
            <Link className="navbar-brand" to="/">
              <img
                src={images['logo.png']}
                className="img-fluid"
                title=""
                alt=""
                style={{ width: "192px; height:42px" }}
              />
            </Link>

            {/* <!-- search --> */}
            <div className="search_box">
              <div
                className="searchtxt"
                spiketip-title="🔍 Search Anything"
                spiketip-pos="left"
                spiketip-color="error"
              >
                <input
                  type="search"
                  name="search"
                  className="form-control"
                  placeholder="Search Product"
                  id="search_item"
                  value={inputValue.value}
                  onKeyDown={e=>handleKeyPress(e)}
                  onChange={e=> setInputValue({clicked:false, value:e.currentTarget.value})}
                />
                <AutoComplete setSearchTerm={setInputValue} searchTerm={inputValue}/>
                <span to="" className="search_icon" onClick={handleSearch}>
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                {/* <ul>
                  {suggestions.map((suggestion) => (
                    <li key={suggestion}>{suggestion}</li>
                  ))}
                </ul> */}
              </div>
              <div className="searchdesg">
                <Link
                  to="https://mangtum.in/admin/vendor/login"
                  target="_blank"
                  spiketip-title="💰 Partner with us & Sell Your Products!"
                  spiketip-pos="top"
                >
                  <i className="fa fa-home vendor-icon" aria-hidden="true"></i>{" "}
                  <span className="d-none d-md-inline">Sell your Product</span>
                </Link>
              </div>
            </div>
            {/* <!-- nav line  --> */}
            <div className="navLine">
              <Link
                to="/userwishlist"
                className="nav-link bookmark"
                spiketip-title="💟 Your Dream Cart!"
                spiketip-pos="left"
              >
                <i className="fa fa-heart-o" aria-hidden="true"></i>
              </Link>
              <a
                href="/checkout"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                className="nav-link cart"
                spiketip-title="🛒 View Cart!"
                spiketip-pos="bottom"
                spiketip-color="success"
              >
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                <span>{totalCart.cart_count}</span>
              </a>
              {/* <Link className="dropdown-item" to="/user-dashboard" spiketip-title="Your Dashboard" spiketip-pos="top">My Profile</Link></li> */}
              {/* href="javascript:void(0)"  */}
              {state.username ? (
                <>
                  <ul className="list-unstyled m-0">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        role="button"
                        id="navbarDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <strong>{state.username}</strong>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            spiketip-title="Your Dashboard"
                            href="/user-dashboard"
                            spiketip-pos="top"
                          >
                            My Profile
                          </a>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/"
                            onClick={handleLogout}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </>
              ) : (
                <Link
                  to="/login"
                  className="signbtn"
                >
                  Sign in
                </Link>
              )}
              {/* <!-- cart modal  --> */}
              <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                    Shop Cart
                  </h5>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {/* <!-- cart list  --> */}
                  <div className="cartList">
                    <div className="cart_img">
                      <img
                        src={images['product-cover-3.png']}
                        className="img-fluid"
                        title=""
                        alt=""
                      />
                    </div>
                    <div className="cartDt">
                      <h5>Pearl Necklace</h5>
                      <div className="weight font-s">50g</div>
                      <div className="remove font-s">
                        <Link to="#link">
                          <i className="ri-delete-bin-4-line"></i> Remove
                        </Link>
                      </div>
                    </div>
                    <div className="qty">
                      <button className="qtyminus" aria-hidden="true">
                        −
                      </button>
                      <input
                        type="number"
                        name="qty"
                        id="qty"
                        min="1"
                        max="10"
                        step="1"
                        value="1"
                        onChange={e=>{}}
                      />
                      <button className="qtyplus" aria-hidden="true">
                        +
                      </button>
                    </div>
                    {/* <!-- price  --> */}
                    <div className="price">₹32.00</div>
                  </div>
                  {/* <!-- cart list  --> */}
                  <div className="cartList">
                    <div className="cart_img">
                      <img
                        src={images['product-cover-4.png']}
                        className="img-fluid"
                        title=""
                        alt=""
                      />
                    </div>
                    <div className="cartDt">
                      <h5>Pearl Necklace</h5>
                      <div className="weight font-s">500g</div>
                      <div className="remove font-s">
                        <Link to="#link">
                          <i className="ri-delete-bin-4-line"></i> Remove
                        </Link>
                      </div>
                    </div>
                    <div className="qty">
                      <button className="qtyminus" aria-hidden="true">
                        −
                      </button>
                      <input
                        type="number"
                        name="qty"
                        id="qty"
                        min="1"
                        max="10"
                        step="1"
                        value="1"
                        onChange={e=>{}}
                      />
                      <button className="qtyplus" aria-hidden="true">
                        +
                      </button>
                    </div>
                    {/* <!-- price  --> */}
                    <div className="price">₹32.00</div>
                  </div>
                  <div className="offcanvas-footer d-flex mt-4">
                    <Link to="/" className="btn btn-dark">
                      Continue Shopping
                    </Link>
                    <Link to="/" className="btn btn-warning ms-3">
                      Continue to Pay
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!-- end modal  --> */}
            </div>
          </div>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-light nav-scroll-fixed"
        id="mainNav"
      >
        <div className="container position-relative">
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            data-bs-toggle="collapse"
            data-bs-target="/magNav"
            aria-controls="magNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            id="magNav"
          >
            <ul className="navbar-nav">
              {headerCategories?.map((category, index) => {
                return (
                  <li
                    className={`nav-item ${
                      window.innerWidth < 768 ? "" : "dropdown"
                    }`}
                    key={index}
                    {...(window.innerWidth < 768
                      ? {
                          onClick: () => toggleSubcategory(category.cat_id),
                        }
                      : null)}
                  >
                    <Link
                      className="nav-link dropdown-toggle"
                      to={`/product-by-category/${category.slug}`}
                      onClick={() => onProductCategoryClick(category.slug)}
                    >
                      {category.cate_name}
                    </Link>
                    {category.sub_categories?.length > 0 && (
                      <ul
                        className={`dropdown-menu ${
                          selectedCategory === category.cat_id ? "show" : ""
                        }`}
                      >
                        {category.sub_categories?.map((sub_category, index) => {
                          return (
                            <li key={index}>
                              <Link
                                className="dropdown-item"
                                to={`/product-category/${category.slug}/${sub_category.sub_cate_slug}`}
                              >
                                {sub_category.subcate_name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>

      {/* small screen header */}
      <MobileHeader headerCategories={headerCategories}
      setSearchTerm={setInputValue}
      searchTerm={inputValue}
      handleSearch={handleKeyPress}
      showModal={showModal} setShowModal={setShowModal}/>
    </div>
  );
}
export default Header;
