import React from 'react'
import { images } from '../../../utils/images'

const TeamCard = () => {
  return (
    <div className='team-card'>
      <figure>
        <img src={images['team1.jpg']} alt="" />
      </figure>
      <div className='card-body'>
        <h5>Username</h5>
        <h6>Profession</h6>
      </div>
    </div>
  )
}

export default TeamCard