import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getproductList, getFiltercode, addWishlist } from "../store/product";
import { useLocation, useNavigate } from "react-router-dom";
import SiteLoader from "../SiteLoader";
import Filters from "../components/General/Filters";
import { images } from "../utils/images";
import { Circles } from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroller";
import { mgtApi } from "../store/axios";

const ProductlistBySubcat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterUrl = window.location.pathname.split("product-category/").pop();
  const catAndSubCatSlugs = filterUrl.split("/");
  const { headerCategories } = useSelector((state) => state.home);
  const categorySlug = catAndSubCatSlugs[0];
  const [categoryId, setCategoryId] = useState(null);

  const subCategorySlug =
    catAndSubCatSlugs.length === 1 ? categorySlug : catAndSubCatSlugs[1];

  useEffect(() => {
    setCategoryId(headerCategories.filter((item) => item.slug === categorySlug));
  }, [headerCategories]);

  useEffect(()=>{
    if(categoryId){
      dispatch(getFiltercode({ cate_id: categoryId[0]?.cat_id }));
    }
  }, [categoryId])

  useEffect(() => {
    setProducts([]);
    setPage(2);
    setLoadMore(true);
    dispatch(getproductList({ subCategorySlug }));
  }, [subCategorySlug]);

  const prodli = useSelector((state) => state.prodLi.data);
  const state = useSelector((state) => state.prodLi);

  //const prodli=productAll.data;

  const filter_array = state.filterList;

  const countlength = () => {
    return (
      <div className="counter grey-text">
        <strong>{state.plength}</strong>
      </div>
    );
  };

  useEffect(() => {
    if (state.prodNotFound) {
      navigate("/page-not-found");
    }
  }, [{ categorySlug, subCategorySlug }]);

  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var user_id = data?.ID;

  const addToWishList = (product_id, index) => {
    dispatch(addWishlist(product_id));

    var heartIcon = document.getElementById("heartIcon-" + index);
    heartIcon.classList.toggle("selected");
    heartIcon.classList.toggle("hearticoncolor");
  };

  /**filter code started */
  const [products, setProducts] = useState([]);
  const filteredProducts = useSelector(
    (state) => state.prodLi.filter_array_products
  );
  //console.log("filteredProducts", filteredProducts)

  useEffect(() => {
    if (filteredProducts?.products?.length > 0) {
      setProducts(filteredProducts?.products);
    }
  }, [filteredProducts]);

  useEffect(() => {
    if (prodli?.data?.product?.length > 0) {
      setProducts(prodli?.data?.product);
    } else {
      setProducts([]);
    }
  }, [prodli]);

  const onProductClick = (link) => {
    navigate(link);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    console.log("Link", link);
  };

  // -------------------------------------------

  const [smallScreen, setSmallScreen] = useState(false);
  const [page, setPage] = useState(2);
  const [loadMore, setLoadMore] = useState(true);
  const [totalPages, setTotalPages] = useState(3);
  const [listStyle, setListStyle] = useState(false);

  async function loadMoreProducts() {
    if (page > 1 && loadMore && products.length > 0) {
      if (page >= totalPages || products.length < 10) {
        setLoadMore(false);
      } else {
        let userDetails = localStorage.getItem("userDetails");
        let userData = JSON.parse(userDetails);
        let user_id = userData?.ID;
        const { data } = await mgtApi.post("product-by-subcategory-web", {
          page,
          slug: {
            subCategorySlug: subCategorySlug,
          },
          user_id,
        });
        setTotalPages(data.total_pages);
        setProducts([...products, ...data?.data?.product]);
        setPage(page + 1);
      }
    }
  }

  return (
    <div>
      <>
        {state.loaderStatus ? (
          <SiteLoader status={state.loaderStatus} />
        ) : (
          <>
            <main>
              <div className="bg-light inner-breadcrumb">
                <div className="container">
                  <div className="breadcrumb-head">
                    <h3>
                      {categorySlug}/{subCategorySlug}
                    </h3>
                    <nav className="breadcrumb-wrap">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                          style={{ fontSize: "12px", paddingTop: "3px" }}
                        >
                          {categorySlug}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <section className="single-wrapper">
                <SiteLoader status={state.loaderStatus} />
                <div className="container">
                  <div className="flex space-between pt-4 pb-4">
                    {countlength()}
                    <div className="counter grey-text">
                      Showing all {products?.length} results
                    </div>
                    <div className="product-filter">
                      <div className="view-style">
                        <h3>Views : </h3>
                        {listStyle ? (
                          <button onClick={(e) => setListStyle(false)}>
                            <img src={images["grid.svg"]} alt="" />
                          </button>
                        ) : (
                          <button>
                            <img
                              src={images["list.svg"]}
                              alt=""
                              onClick={(e) => setListStyle(true)}
                            />
                          </button>
                        )}
                      </div>
                      <button
                        className="filter-btn"
                        onClick={(e) => setSmallScreen(true)}
                      >
                        Filters
                      </button>
                    </div>
                  </div>
                  {/* <!-- product  --> */}
                  <div className="row">
                    <div
                      className={`col-lg-3 col-md-4 ${
                        smallScreen ? "resp-show" : "resp-hide"
                      }`}
                    >
                      <Filters
                        options={filter_array}
                        setSmallScreen={setSmallScreen}
                      />
                    </div>
                    <div className="col-lg-9 col-md-8">
                      <InfiniteScroll
                        pageStart={2}
                        className="flex-product flexgrow"
                        loadMore={loadMoreProducts}
                        hasMore={loadMore}
                        loader={
                          <div className="spinner">
                            <Circles
                              type="Circles"
                              color="#f6a92c"
                              height={60}
                              width={60}
                            />
                          </div>
                        }
                      >
                        {products?.map((plist, index) => {
                          return (
                            <div
                              className={listStyle ? "list-item" : "item"}
                              key={index}
                            >
                              <div className="product-row wow animated fadeInUp">
                                <span
                                  onClick={() =>
                                    onProductClick(
                                      `/shop/${categorySlug}/${subCategorySlug}/${plist.pro_slug}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="product-cover"
                                    style={listStyle ? { width: "200px" } : {}}
                                  >
                                    <img
                                      src={plist.main_image}
                                      className="img-fluid"
                                      title=""
                                      alt=""
                                      onError={(e) =>
                                        (e.target.src =
                                          images["defaultImg.png"])
                                      }
                                    />
                                    <div className="wishlist-icon">
                                      <i
                                        className={
                                          plist?.wishlist_status
                                            ? "fa fa-heart hearticoncolor selected"
                                            : "fa fa-heart"
                                        }
                                        aria-hidden="true"
                                        wish-class
                                        id={`heartIcon-${index}`}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          addToWishList(plist.id, index);
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </span>
                                {/* <!-- product-cont  --> */}
                                <div className="product-content p-3">
                                  <h4>
                                    {plist.pro_name.length > 15
                                      ? plist.pro_name.slice(0, 15) + "..."
                                      : plist.pro_name}
                                  </h4>
                                  <div className="price">
                                    <div className="grey-text">
                                      <span className="text-warning">
                                        ${plist.sell_price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </section>
              <hr />
            </main>
          </>
        )}
      </>
    </div>
  );
};

export default ProductlistBySubcat;
