import { createSlice } from "@reduxjs/toolkit";
import { mgtApi } from "./axios";
import { convertPayloadToQueryString } from "../utils/helper";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFiterResponse = createAsyncThunk('product/getFiterResponse', async ({ attr, prices, category }, thunkAPI) => {
    const str = convertPayloadToQueryString(attr);
        try {
          const { data } = await mgtApi.post(
            `/productsearch?search=${category}&price_between=${prices[0]},${prices[1]}&${str}`
          );
        return data;
        } catch (error) {
          console.log(error);
        }
  });


//show the loader
const intialProductState = {
  search_item: null,
  search_total: 0,
  data: [],
  search_page: 1,
  singleProducts: null,
  productByCategory: [],
  plength: [],
  prodNotFound: false,
  galleryimages: [],
  cate_id: {},
  filterList: [],
  productVariations: [],
  variation_status: false,
  beadFilter: [],
  shapeFilter: [],
  styleFilter: [],
  loaderStatus: true,
  related_prod: [],
  vendorInfo: [],
  wishList: [],
  autosuggestProduct: [],
  filterEnabled: false,
  beadCheckboxState: [],
  userCartProductCount: 1,
  statuskey: null,
  message: null,
  filter_array_products: [],
};

const productSlice = createSlice({
  name: "product",
  initialState: intialProductState,
  reducers: {
    productlistsbySubcatSuccess(state, action) {
      state.data = action.payload;
      state.search_total = action.payload.total_pages;
      state.filterEnabled = false;
      state.beadCheckboxState = [];
    },

    singleproductDetailSuccess(state, action) {
      state.singleProducts = action.payload;
      state.galleryimages = state.singleProducts?.product?.gallery; //Object.entries(JSON.parse(state.singleProducts.product.gallery));
      state.userCartProductCount = 1;
    },
    singleproductDetailFailure(state, action) {},
    productByCategorySuccess(state, action) {
      state.productByCategory = action.payload;
      state.search_total = action.payload.total_pages;
      state.filterEnabled = false;
      state.beadCheckboxState = [];
    },
    productByCategoryFailure(state, action) {
      state.productByCategory = action.payload;
    },
    productByCategorycount(state, action) {
      state.plength = action.payload;
    },
    category_id(state, action) {
      state.cate_id = action.payload;
    },
    filterListsSuccess(state, action) {
      state.filterList = action.payload;
    },
    filterListsFailure(state, action) {
      state.filterList = action.payload;
    },
    product_variationsList_success(state, action) {
      state.productVariations = action.payload;
      state.variation_status = true;
    },
    product_variationsList_failure(state, action) {
      state.productVariations = action.payload;
      state.variation_status = false;
      state.loaderStatus = false;
    },
    bead_filter(state, action) {
      state.beadFilter = action.payload;
    },
    filterProducts(state, action) {
      state.filter_array_products = action.payload;
      state.search_total = action.payload.total_pages;
      state.filterEnabled = true;
    },
    updatePageNumber(state, action) {
      state.search_page = action.payload.page;
      state.slug = action.payload.slug;
      state.user_id = action.payload.user_id;
    },
    updateLoaderStatus(state, action) {
      state.loaderStatus = action.payload;
    },
    relatedProducts(state, action) {
      state.related_prod = action.payload;
    },
    vendorinfo_Success(state, action) {
      state.vendorInfo = action.payload;
      state.vendorid = action.payload.vendorid;
    },
    wishAddSuccess(state, action) {
      state.statuskey = "success";
      state.message = "Added to Wishlit";
      state.wishlist_status = action.payload;
    },
    wishRemoveSuccess(state, action) {
      state.statuskey = "success";
      state.message = "Added to Wishlit";
      state.wishlist_status = action.payload;
    },
    fetchWishlist(state, action) {
      state.wishList = action.payload;
    },
    fetch_suggestList_product(state, action) {
      state.autosuggestProduct = action.payload;
    },
    updateBeadCheckboxFilterSuccess(state, action) {
      state.beadCheckboxState = action.payload;
    },
    userCartProductCount(state, action) {
      state.userCartProductCount = action.payload;
    },
    wishAddFailure(state, action) {
      state.status = "error";
      state.message = "Failed to add wishlist";
    },
  },
  extraReducers: {
    [getFiterResponse.pending]:(state) => {
        state.statuskey = 'pending'
    },
    [getFiterResponse.fulfilled]:(state, {payload}) => {
        state.statuskey = 'fullfilled';
        state.filter_array_products = {
            products : payload.products,
            total_page : payload.total_page
        };        
        delete payload['products']; 
        delete payload['total_page']; 
        state.filterList = {
            ...state.filterList, ...payload
        }
    },
    [getFiterResponse.failed]:(state) => {
        state.statuskey = 'failed'
    }
  }
});
export const productActions = productSlice.actions;

/*export const getproductList = (payload) => {
  
    const cat_slug = payload.categorySlug;
    const sub_cat_slug = payload.subCategorySlug;
   
    return async (dispatch) => {
        try {
             
             await mgtApi.get('/product-by-subcategory/'+sub_cat_slug).then(res => {
                ////consolelog("resd", res);
                ////consolelog("resd", res.data.data);
                if(res.data.status == "success"){
                   
                    dispatch(productActions.productlistsbySubcatSuccess(res.data.data))
                }
                if(res.data.status == "fail"){
                    dispatch(productActions.productlistsbySubcatFailure(res.data.msg))
                    ////consolelog('error', res.data.msg)
                   // window.location.href = 'http://localhost:3000/page-not-found';
                }
            })

            // dispatch(homeActions.updateformfields(input))
        }
        catch (e) {
            return ////consolelog(e);
        }
    }
}*/

export const getproductList = (sub_cat_slug) => {
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var user_id = data?.ID;
  let page = 1;
  return async (dispatch) => {
    dispatch(
      productActions.updatePageNumber(
        { slug: sub_cat_slug, user_id: user_id, page: page },
        { loaderStatus: false }
      )
    );
    dispatch(productActions.updateLoaderStatus(true));
    try {
      await mgtApi
        .post("/product-by-subcategory-web", {
          slug: sub_cat_slug,
          user_id: user_id,
          page: page,
        })
        .then((res) => {
          if (res.data.status == "success") {
            dispatch(productActions.productlistsbySubcatSuccess(res.data));
          }
        });

      // dispatch(homeActions.updateformfields(input))
    } catch (e) {
      return;
    }
    dispatch(productActions.updateLoaderStatus(false));
  };
};

export const getSingleproductDetail = (payload) => {
  const prodSlug = payload.prodSlug;

  return async (dispatch) => {
    dispatch(productActions.updateLoaderStatus(true));
    try {
      await mgtApi.get("/single-products/" + prodSlug).then((res) => {
        if (res.data.status == "success") {
          dispatch(
            productActions.singleproductDetailSuccess(res.data.data, {
              loaderStatus: false,
            })
          );
        }
        if (res.data.status == "fail") {
          dispatch(productActions.singleproductDetailFailure(res.data.msg));
        }
        dispatch(productActions.updateLoaderStatus(false));
      });
    } catch (e) {
      return;
    }
  };
};
export const getProductByCategory = (payload) => {
  const slug = payload.catslug;
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var user_id = data?.ID;
  let page = 1;
  return async (dispatch) => {
    dispatch(
      productActions.updatePageNumber({
        slug: slug,
        user_id: user_id,
        page: page,
      })
    );
    dispatch(productActions.updateLoaderStatus(true));
    try {
      await mgtApi
        .post(
          "/product-by-category-web",
          { slug: slug, user_id: user_id, page: page },
          { loaderStatus: false }
        )
        .then((res) => {
          var count = Object.keys(res).length;
          // //consolelog("res", res.data)
          if (res.data.status == "success") {
            dispatch(productActions.productByCategorySuccess(res.data, page));
            var catId = res.data.data.product["0"].cate_id;
            ////consolelog('catId',catId);
            dispatch(productActions.productByCategorycount(res.data.msg));
            dispatch(productActions.category_id(catId));
          }
          if (res.data.status == "fail") {
            dispatch(productActions.productByCategoryFailure(res.data.msg));
          }
        });
    } catch (e) {}
    dispatch(productActions.updateLoaderStatus(false));
  };
};

export const getFiltercode = (payload) => {
  return async (dispatch) => {
    var category_id = payload.cate_id;
    try {
      await mgtApi
        .post("/get-filter-options", { category_id: category_id })
        .then((res) => {
          if (res.data.status == "ok") {
            dispatch(productActions.filterListsSuccess(res.data));
            dispatch(productActions.bead_filter(res.data.filter["0"]));
          }

          if (res.data.status == "error") {
            dispatch(productActions.filterListsFailure(res.data.msg));
          }
        });
    } catch {}
  };
};
export const getProductVariation = (payload) => {
  return async (dispatch) => {
    var product_id = payload.product_id; //alert(category_id);
    try {
      await mgtApi
        .post("/product-variations", { product_id: product_id })
        .then((res) => {
          if (res.data.status == "ok") {
            if (res.data.variation) {
              dispatch(productActions.product_variationsList_success(res.data));
            } else {
              dispatch(productActions.product_variationsList_failure(res.data));
            }
          } else {
            dispatch(productActions.product_variationsList_failure("Failed"));
          }
        });
    } catch {}
  };
};

export const getFilterProduct = (payload, page = "") => {
  return async (dispatch) => {
    var userDetails = localStorage.getItem("userDetails");
    var data = JSON.parse(userDetails);
    var user_id = data?.ID;
    var custom_attributes = JSON.stringify(payload.beadCheckboxState);
    custom_attributes = custom_attributes.replace(
      /([a-zA-Z0-9-]+):([a-zA-Z0-9-]+)/g,
      '"$1":"$2"'
    );
    var slug = "";
    // var page = '';
    ////consolelog('custom_attributes',custom_attributes);
    try {
      // await mgtApi.post('/filter-product', { user_id: user_id, custom_attributes: custom_attributes }).then(res => {
      await mgtApi
        .post("/filter-product-web", {
          user_id: user_id,
          custom_attributes: custom_attributes,
          page: page,
        })
        .then((res) => {
          if (res.data.status == "success") {
            dispatch(
              productActions.updateBeadCheckboxFilterSuccess(
                payload.beadCheckboxState
              )
            );
            dispatch(productActions.filterProducts(res.data));
            dispatch(
              productActions.updatePageNumber({
                slug: slug,
                user_id: user_id,
                page: page,
              })
            );
          }
        });
    } catch {}
  };
};
export const pagination_product_bycategory = (slug, user_id, page) => {
  return async (dispatch) => {
    dispatch(
      productActions.updatePageNumber({
        slug: slug,
        user_id: user_id,
        page: page,
      })
    );
    try {
      await mgtApi
        .post("/product-by-category-web", {
          slug: slug,
          user_id: user_id,
          page: page,
        })
        .then((res) => {
          if (res.data.status == "success") {
            // dispatch(productActions.updateBeadCheckboxFilterSuccess([]))
            dispatch(productActions.productByCategorySuccess(res.data, page));
            var catId = res.data.data.products["0"].cate_id;
            dispatch(productActions.productByCategorycount(res.data.msg));
            dispatch(productActions.category_id(catId));
            dispatch(
              productActions.updatePageNumber({
                slug: slug,
                user_id: user_id,
                page: page,
              })
            );
          } else {
            // window.location.href="http://localhost:3000/page-not-found";
          }
        });
    } catch (e) {
      return;
    }
  };
};
export const pagination_product_by_subcategory = (slug, user_id, page) => {
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var user_id = data?.ID;

  return async (dispatch) => {
    try {
      await mgtApi
        .post("/product-by-subcategory-web", {
          slug: slug,
          user_id: user_id,
          page: page,
        })
        .then((res) => {
          if (res.data.status == "success") {
            dispatch(
              productActions.productlistsbySubcatSuccess(res.data, page)
            );

            dispatch(
              productActions.updatePageNumber({
                slug: slug,
                user_id: user_id,
                page: page,
              })
            );
          } else {
            // window.location.href="http://localhost:3000/page-not-found";
          }
        });
    } catch (e) {
      return;
    }
  };
};
export const getRelatedProducts = (subcatId, product_id, user_id) => {
  return async (dispatch) => {
    try {
      await mgtApi
        .post("/related-products-web", {
          user_id: user_id,
          product_id: product_id,
          subcategory_id: subcatId,
        })
        .then((res) => {
          if (res.data.status == "ok") {
            dispatch(productActions.relatedProducts(res.data));
          }
        });
    } catch {}
  };
};

export function getVendorinfo(vendor_id, shipping_profile) {
  //  vendor_id,shipping_profile
  //consolelog('vendor_id',vendor_id);
  //consolelog('shipping_profile',shipping_profile);
  return async function (dispatch) {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    // dispatch(productActions.updateLoaderStatus(true))
    try {
      const response = await mgtApi.post("/vendor-info-web", {
        shipping_profile: shipping_profile,
        vendor_id: vendor_id,
      });
      if (response.data.status === "ok") {
        dispatch(productActions.vendorinfo_Success(response.data));
      }
      if (response.data.status === "error") {
        dispatch(productActions.vendorinfo_Failure(response.data.msg));
      }
      dispatch(productActions.updateLoaderStatus(false));
    } catch (error) {
      dispatch(productActions.updateLoaderStatus(false));
    }
  };
}
export function clearFilterArray() {
  //  ////consolelog('reducer',vendorid);
  return async function (dispatch) {
    try {
      dispatch(productActions.clearFilter());
    } catch (error) {
      ////consoleerror(error);
    }
  };
}
export function addWishlist(product_id) {
  //  ////consolelog('reducer',vendorid);
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var user_id = data?.ID;
  return async function (dispatch) {
    try {
      const response = await mgtApi.post("/addwishlist", {
        product_id: product_id,
        user_id: user_id,
      });
      if (response.data.wishlist_status == 1) {
        console.log("added to wishlist");
        dispatch(productActions.wishAddSuccess(response.data.wishlist_status));
      } else {
        dispatch(
          productActions.wishRemoveSuccess(response.data.wishlist_status)
        );
      }
    } catch (error) {
      dispatch(productActions.wishAddFailure("Error Occured"));
      ////consoleerror(error);
    }
  };
}

export const getWishlistProduct = (payload) => {
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var user_id = data?.ID;
  return async (dispatch) => {
    dispatch(loaderStart());
    try {
      const response = await mgtApi.post("/user-wishlist", {
        user_id: user_id,
      });
      if (response.data.status == "1") {
        dispatch(productActions.fetchWishlist(response.data));
        dispatch(productActions.wishAddSuccess(1));
        dispatch(loaderStop());
      } else {
        dispatch(loaderStop());
      }
    } catch (error) {
      ////consoleerror(error);
    }
  };
};

export const getProductCartCount = (user_id, product_id) => {
  return async (dispatch) => {
    // dispatch(loaderStart());
    try {
      const response = await mgtApi.post("/user-check-prod-qty", {
        user_id: user_id,
        product_id: product_id,
      });
      //consolelog("cart count response", response.data);
      if (response.data.status === "ok") {
        dispatch(
          productActions.userCartProductCount(response.data.avail_product_qty)
        );
        // dispatch(productActions.wishAddSuccess(1));
        // dispatch(loaderStop());
      } else {
        // dispatch(loaderStop());
      }
    } catch (error) {
      ////consoleerror(error);
    }
  };
};

export const loaderStart = () => {
  return async (dispatch) => {
    dispatch(productActions.updateLoaderStatus(true));
  };
};

export const loaderStop = () => {
  return async (dispatch) => {
    dispatch(productActions.updateLoaderStatus(false));
  };
};
export const getAutosuggestion_List = (keyword) => {
  return async (dispatch) => {
    try {
      const response = await mgtApi.post("/search-product", {
        keyword: keyword,
      });
      if (response.data.status == "success") {
        dispatch(
          productActions.fetch_suggestList_product(response.data.products)
        );
      }
    } catch (error) {
      ////consoleerror(error);
    }
  };
};

export default productSlice.reducer;
