export function convertPayloadToQueryString(payload) {
    let queryString = '';
    payload.forEach((item, index) => {
      const attrId = item.id;
      const attributeValue = item.attribute_value;
      queryString += `attr_id=${attrId}&attribute_value=${attributeValue}`;
      if (index !== payload.length - 1) {
        queryString += '&';
      }
    });
    return queryString;
  }