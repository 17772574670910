import React from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { useEffect } from 'react';
import {getPrivacypolicy, get_meta} from '../store/home'
import SiteLoader from "../SiteLoader";

import { Helmet } from 'react-helmet';
import SiteLogo  from "../components/img/logo.png";

const Termscondition = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrivacypolicy())
  }, [])
  const termscondition = useSelector(state => state.home.tmc)
  const state = useSelector(state => state.home)

  
   // META TAGS START
   useEffect(() => {
    dispatch(get_meta('terms-and-condition'))},['terms-and-condition'])
    const metaTags = state.allmeta;

   const siteUrl = window.location.href;
  // META TAGS END

  return (
    <div>
      <SiteLoader status={state.loaderStatus}/>
      <div className="bg-light inner-breadcrumb">
        <div className="container">
          <div className="breadcrumb-head">
            <h3>Terms and Conditions</h3>
            <Helmet>
                 <meta charSet="utf-8" />
                 <title>{metaTags?.meta_data?.meta_title}</title>
                 <meta name="description" content={metaTags?.meta_data?.meta_description}/>
                 <meta property="og:type" content="website" />
                 <meta property="og:url" content={siteUrl} />
                 <meta property="og:title" content={metaTags?.meta_data?.og_title} />
                 <meta property="og:description" content={metaTags?.meta_data?.og_description}/>
                 <meta property="og:image" content={SiteLogo}  />
            </Helmet>
            <nav className="breadcrumb-wrap">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <section className="single-wrapper section-wrapper">
        <div className="container">
          <>
        {termscondition?.map(t => ( 
          <>        
        <div className="content">
        <p dangerouslySetInnerHTML={{__html: t.content}}></p>
          </div> </>
             ))}</>
        </div>
     
      </section>
    </div>
  )
}

export default Termscondition