import React, { useReducer } from 'react';
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { verifyOtpfield, checkValidateOTP } from "../store/auth";
import {Link, useNavigate } from 'react-router-dom';
const Authentication = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intialValues = { otp_key: "" }

    const form_fields = useSelector(state => state.auth.passcode)
    //const state = useSelector(state => state.home)


    useEffect(() => {
    }, [form_fields])

    const state = useSelector(state => state.auth)
    const [otp_er, setOtpErrors] = useState(false)
    console.log(state.OtpStatus);
    useEffect(() => {
        var otp_er={};
        if (state.OtpStatus) {
            navigate('/login')
        }
        
    }, [state.OtpStatus])
    const msgOnsuccess = () => {
        
        if(state.registerStatus){
            return  <div className="row manage-center-msg"><p>{state.registerMsg}</p></div>
        } 
        else{
            return  <div className="row manage-center-msg"><p>{state.registerMsg}</p></div>
        }
      }

      const otpMessage = () => {
        
        if(state.OtpStatus){
            return  <span className='register-msg-style'>{state.otpMesg}</span>
        } 
        else{
            return  <span className='register-msg-style'>{state.otpMesg}</span>
        }
      }

    const [error, setErrors] = useState(false)
    const handleverify = (e) => {
        const { name, value } = e.target;
        var input = { field: name, value: value }
        dispatch(verifyOtpfield(input))
    };

   
    const handelSubmit = (e) => {
        e.preventDefault();
        if (validate(form_fields)) {
            dispatch(checkValidateOTP(form_fields))
        }
    }
    

    const validate = (values) => {
        const error = {};
        const regex = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
        const pass_regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!values.otp_key) {
            error.otp_key = "Please enter the otp!"
        }

        setErrors(error);
        return Object.values(error).length > 0 ? false : true;
    }


    return (
        <div>
            <main>
                
            <div className="bg-light inner-breadcrumb">
                <div className="container">
                <div className="breadcrumb-head">
                    <h3>Verify OTP</h3>
                    <nav className="breadcrumb-wrap">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page" style={{'fontSize':'12px', 'paddingTop':'3px'}}>Verify OTP</li>
                    </ol>
                    </nav>
                </div>
                </div>
            </div>
            <section className="single-wrapper section-wrapper">
                <div className="container">
                    {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}
                    <div className="row pt-2 pb-5 justify-content-center" id="otp_div">
                       {msgOnsuccess()}
                        <div className="col-md-6 login-wrap">
                            {/* <h3 className="text-center">Verify OTP</h3> */}

                            <form onSubmit={handelSubmit}>
                                <div className="form-group">
                                    <label>Enter OTP</label>
                                    <input type="text" name="otp_key" onChange={handleverify} className="form-control" placeholder="Enter OTP" />
                                </div>
                                <p className="register_error">{error.otp_key}</p>

                                <div className="col-6 lost">
                                     <button className="btn btn-warning w-100" style={{'font-weight':'700'}}>Submit</button>
                                </div>
                                    {otpMessage()}
                                
                            </form>

                        </div>
                    </div>
                </div>




            </section>
            </main>
        </div>
    );
}

export default Authentication;