import React from "react";
import {images} from "../../../utils/images"

const FirstPaint = () => {
  return (
    <div className="paint" style={{
        backgroundImage: `url(${images["none.png"]})`,
      }}>
      <div className="container">
        <div className="paint-text">
          <h5>ABOUT COMPANY</h5>
          <h1>ABOUT US</h1>
          <h4>
            We know how large objects will act, but things on a small scale
          </h4>
          <button>Get Quote Now</button>
        </div>
      </div>
    </div>
  );
};

export default FirstPaint;
