import React, { useEffect, useState } from "react";
import { Link, Navigate, redirect, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
// import { CheckoutBanner, StripeLogo } from "../Images";

import { user_defaultAddress,updateDefaultAddress,addressUpdateModalOpen,user_checkout } from '../checkoutpage/checkout_reducer';
import { getUserAddress } from "../store/home";

import Modal from "react-bootstrap/Modal";
// import {
//   checkoutData,
//   stripeData,
//   paypalData,
//   expresShip,
//   giftShipNew,
// } from "../store/checkout";
import { mgtApi } from "../store/axios";
import {
  checkoutData,
  stripeData,
  paypalData,
  expressShip,
  giftShipNew,
} from "../store/checkout";

import StripeCheckout from 'react-stripe-checkout';

const ShippingInfo = () => {


  const [stripeKey, setStripeKey] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(user_defaultAddress())
  }, [])
  const mycartLists = useSelector(state => state.checkoutp.checkout_data);
  const myAddress_lists = useSelector(state => state.checkoutp.checkout_address_data);
  const stripeCred = useSelector(state => state.checkout.stripeKey)

  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  var name = data?.full_name;
  var phone = data?.mobile;
  var mail = data?.email;

  // useEffect(() => {
  //   let shipId = props.location.state?.shipid;
  //   dispatch(checkoutData(shipId));
  // }, []);

  const onToken = (token) => {

    let shipId = myAddress_lists?.shipping_address?.id;
    let stripeData = new FormData();
    stripeData.append("stripe_token", token.id);
    stripeData.append("shipping_address", shipId);
    // stripeData.append("experss_products", expressShip);
    // stripeData.append("gift_products", giftItems);
    var userDetails = localStorage.getItem("userDetails");
        var data = JSON.parse(userDetails);
        var user_id = data?.ID;
    mgtApi.post("stripe-transaction", stripeData).then((response) => {
      if (response.status === 'success') {
        navigate('/user-dashboard', {
          state: {
            user_id: user_id
          }
        })
      }
      // setLoaderStatus(false);
      // setStripeMsg(response);
    });
  };



  useEffect(() => {
    if (stripeCred.length < 1) {
      window.location.href = "/checkout"
      // redirect('/checkout')
    }
    // setStripeKey(stripeCred.stripe_key)
  }, [])

  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    // dispatch(addressUpdateModalOpen());
    dispatch(getUserAddress());
    setShowModal(true);
    
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  
const addressLists = useSelector(state => state.home.addressList);

const [selectedValue, setSelectedValue] = useState(myAddress_lists?.shipping_address?.id);

function handleChange(event) {
  setSelectedValue(event.target.value);

  setShowModal(false);
  dispatch(updateDefaultAddress(event.target.value))
  dispatch(user_defaultAddress())
  toast("Shipping address changed successfully.");
}
const addStatus = useSelector(state => state.checkoutp.add_status);
useEffect(() => {
  setShowModal(false);
}, [addStatus])
// if(addStatus == true){
//   setShowModal(false);
// }
  return (
    <div>

      <main>
        <div className="bg-light inner-breadcrumb">
          <div className="container">
            <div className="breadcrumb-head">
              <h3>Checkout Page</h3>
              <nav className="breadcrumb-wrap">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href=" ">Home</a></li>
                  <li className="breadcrumb-item"><a href=" ">Shop</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Cart</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <section className="single-wrapper section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="cart-billing">
                  {/* <!-- personal details  --> */}
                  <div className="cart-bill-card">
                    <div className="bill-between">
                      <h4>Personal Details <i className="ri-checkbox-circle-fill text-warning"></i></h4>
                      <div className="flex-content d-block mt-3">
                        <div className="fl-item mb-2"><input type="text" name="name" className="form-control" value={name} /></div>
                        <div className="fl-item mb-2"><input type="number" name="phone" className="form-control" value={phone} /></div>
                        <div className="fl-item mb-2" ><input type="email" name="email" className="form-control" value={mail} style={{ "width": "100%" }} /></div>
                      </div>
                    </div>
                    <div className="bill-between">
                      {/* <a className="btn btn-light btn-md" href="/personal">Save</a> */}
                    </div>
                  </div>
                  {/* <!-- shipping address  --> */}
                  {/* <!-- Billing address  --> */}
                  <div className="cart-bill-card">
                    <div className="bill-between">
                      <h4>Billing Address <i className="ri-checkbox-circle-fill text-warning"></i></h4>
                      <div className="flex-content">
                        <div className="fl-item">{myAddress_lists?.billing_address?.street_address + ', ' + myAddress_lists?.billing_address?.postcode + ', ' + myAddress_lists?.billing_address?.city + ', ' + myAddress_lists?.billing_address?.state + ', ' + myAddress_lists?.billing_address?.country}</div>

                      </div>
                    </div>

                  </div>
                  <div className="cart-bill-card">
                    <div className="bill-between">
                      <h4>Shipping Address <i className="ri-checkbox-circle-fill text-warning"></i></h4>
                      <div className="flex-content">
                        <div className="fl-item">{myAddress_lists?.shipping_address?.street_address + ', ' + myAddress_lists?.shipping_address?.postcode + ', ' + myAddress_lists?.shipping_address?.city + ', ' + myAddress_lists?.shipping_address?.state + ', ' + myAddress_lists?.shipping_address?.country}</div>

                      </div>
                    </div>
                    <div className="bill-between">
                      <Link onClick={handleModalOpen} className="btn btn-warning">Change</Link>
                    </div>
                  </div>

                  {/* <!-- Payment Method  --> */}
                  <div className="cart-bill-card align-items-start hide">
                    <div className="bill-between">
                      <h4>Payment Method </h4>
                      <div className="form-check">
                        <div className="check-head"><i className="ri-bank-card-2-line"></i> Debit Card</div>
                      </div>
                    </div>
                    <div className="bill-between">
                      <a href=" " className="btn btn-light btn-md">Change</a>
                    </div>
                  </div>

                </div>
              </div>
              {/* <!-- order  --> */}
              <div className="col-md-4">
                <div className="order-wrap">
                  <h3 className="heading-3">Your Order</h3>
                  <div className="flex-item border-line">
                    <div className="flexItm hide">
                      <div className="item-img">
                        <img src="assets/img/img3.jpg" className="img-fluid" title="" alt="" />
                      </div>
                      <div className="cart-item-dtl">
                        <h4>Pearl Necklace</h4>
                        <div className="dropdown item-dropdown">
                          <a className="btn dropdown-toggle" href=" " role="button" id="dropdownItem" data-bs-toggle="dropdown" aria-expanded="false">
                            1 Item
                          </a>
                          <ul className="dropdown-menu" aria-labelledby="dropdownItem">
                            <li><a className="dropdown-item" href=" ">1 Item</a></li>
                            <li><a className="dropdown-item" href=" ">2 Item</a></li>
                            <li><a className="dropdown-item" href=" ">3 Item</a></li>
                          </ul>
                        </div>
                        <div className="price-item">
                          $1,139.33
                        </div>
                      </div>
                    </div>
                    {/* <hr /> */}
                    <div className="flex-bill">
                      <div className="flex-list">
                        <div>Quantity</div>
                        <div className="bold">{mycartLists.cart_count}</div>
                      </div>
                      <div className="flex-list">
                        <div>Subtotal</div>
                        <div className="bold">${mycartLists.subtotal}</div>
                      </div>
                      <div className="flex-list">
                        <div>Delivery Charges</div>
                        <div className="bold">${mycartLists.delivery_charge ? mycartLists.delivery_charge : 0}</div>
                      </div>
                      <div className="flex-list">
                        <div>Discount</div>
                        <div className="bold">${mycartLists.discount ? mycartLists.discount : 0}</div>
                      </div>
                      <div className="flex-list mt-5">
                        <div>Total</div>
                        <div className="bold">${mycartLists.total}</div>
                      </div>
                    </div>
                    <br />
                    {stripeCred ?
                      <Link to="/shipping-info">
                        <span>{stripeKey}</span>
                        <StripeCheckout
                          className="btn btn-md btn-warning w-100"
                          name="Mangtum"
                          amount={mycartLists.total * 100}
                          token={onToken}
                          stripeKey={stripeCred && stripeCred.stripe_key}
                        />
                        {/* <button className="btn btn-md btn-warning w-100">Proceed to Pay</button> */}
                      </Link>
                      : null}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
        {/* <!-- clients  --> */}
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </main>

      {/* modal start */}

      <Modal show={showModal} onHide={handleModalClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title><h5 className="modal-title" id="addAddressLabel">
            Select a address for shipping:
          </h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="containerr">
    
    <div className="row">
        <div className="col-lg-12">
           
            <ul className ="address-ul">
            {addressLists?.map(addresslist => {
              if(addresslist.is_billing == 0){
                return (
                  <>
             <li>
                <input
                  type="radio"
                  id={addresslist.id}
                  name="address"
                  className="form-check-input"
                  defaultValue={addresslist.id}
                  defaultChecked={myAddress_lists?.shipping_address?.id === addresslist.id}
                  onChange={handleChange}
                />
                <label htmlFor={addresslist.id} style={{'color':'#ffc107'}}><strong>{addresslist.name}</strong>,&nbsp;</label>
                <span>
                  {addresslist.street_address}, {addresslist.street_address2}, {addresslist.city}, {addresslist.state}, {addresslist.country}- {addresslist.postcode}
                </span>
                <div className="check"></div>
              </li>
              </>
                )
            }
              })}
             
            </ul>
            
        </div>
        <div className="col-md-12 center-btn">
                    {/* <button className="btn btn-sm btn-warning w-50" onClick={() => updateAddress()}>Update Address</button> */}
          </div>
         
    </div>
  
</div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button onClick={handleModalClose}>Cancel</button> */}
        </Modal.Footer>
      </Modal>
      {/* modal end */}

    </div>

  )
}

export default ShippingInfo