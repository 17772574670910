import axios from "axios";

const token = localStorage.getItem("userDetails");

const authToken = token && JSON.parse(token).token;

export const mgtApi = axios.create({
  //Staging Api 
   baseURL: "https://mangtum.in/admin/api/v1.0/",
   headers: {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json'
  }
});




