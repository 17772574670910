import { createSlice } from "@reduxjs/toolkit";
import { mgtApi } from '../store/axios';

//show the loader
var userDetails = localStorage.getItem("userDetails");
var data = JSON.parse(userDetails);
var user_id = data?.ID;

const intialCheckoutState = { checkout_data:[],cartEmpty:false,checkout_address_data:[],cartStatus_update:{}, cart_removestatus:false, exp_status:false,checkdex:0,exp_gift_status:false,checkgift:0,addressList: [],add_status:false,cartupdateFail:{},cartfailStatus:false,loaderStatus:false}

const checkoutSlice = createSlice({
    name: 'checkoutp',
    initialState: intialCheckoutState, 
    reducers: {
        checkoutList(state,action){
                state.checkout_data = action.payload
                state.cartEmpty = false
        },
        checkoutList_cartEmpty(state,action){
                state.cartEmpty = true
        },
        default_addressLists(state,action){
            state.checkout_address_data = action.payload
        },
        updateCartSuccess(state,action){
            state.cartStatus_update = action.payload
        },
        updateCartFailure(state,action){
            state.cartStatus_update = action.payload
            state.cartfailStatus = false
        },
        updateCart_Failure(state,action){
            state.cartupdateFail = action.payload
            state.cartfailStatus = true
        },
        removeSuccess(state,action){
            state.cart_removestatus = true
            state.cartEmpty = true
        },
        checkexpress_check(state,action){
            state.exp_status = true
            state.checkdex=action.payload.is_checked
        },
        checkexpress_uncheck(state,action){
            state.exp_status = false
            state.checkdex=action.payload.is_checked
        },
        checkgift_check(state,action){
            state.exp_gift_status = true
            state.checkgift=action.payload.is_checked
        },
        checkgift_uncheck(state,action){
            state.exp_gift_status = false
            state.checkgift=action.payload.is_checked
        },
        userAddressDetailsSuccess(state, action) {
            state.addressList = action.payload
        },
        addressUpdateModalOpen (state, action){
            state.add_status =  action.payload;
           
        },
        addressUpdate(state,action){
            state.add_status = false;
        },
        updateLoaderStatus(state, action) {
            state.loaderStatus = action.payload
        }
    }
})

export const checkoutActions = checkoutSlice.actions;


export const user_checkout = () => {

    var userDetails = localStorage.getItem("userDetails");
        var data = JSON.parse(userDetails);
        var user_id = data?.ID;
    return async (dispatch) => {
        // dispatch(checkoutActions.checkoutList_cartEmpty([]))
        dispatch(checkoutActions.updateLoaderStatus(true))
        try {
            await mgtApi.post('/usercheckout',{user_id:user_id})
                .then((response) => {
                    if(response.data.status === 'ok'){
                        dispatch(checkoutActions.checkoutList(response.data,{ loaderStatus: false }));
                    }
                    if(response.data.status === 'nok'){
                        dispatch(checkoutActions.checkoutList_cartEmpty(response.data));
                    }
                    dispatch(checkoutActions.updateLoaderStatus(false))
                })
            }
        catch (e) {
            return 
        }
    }
}
export const user_defaultAddress = () => {

    var userDetails = localStorage.getItem("userDetails");
        var data = JSON.parse(userDetails);
        var user_id = data?.ID;
    return async (dispatch) => {
        try {
            await mgtApi.post('/user-default-address',{user_id:user_id})
                .then((response) => {
                    if(response.data.status === 'success'){
                        dispatch(checkoutActions.default_addressLists(response.data));
                    }
                })
            }
        catch (e) {
            return 
        }
    }
}
export const updateMycart = (payload) => {
   let cart_id = payload.cart_id;
   let type = payload.type;
    return async (dispatch) => {
        try {
            await mgtApi.post('/user-update-cart',{cart_id:cart_id,type:type})
                .then((response) => {
                    
                    if(response.data.status === 'ok'){
                        dispatch(checkoutActions.updateCartSuccess(response.data));
                        
                    }
                    if(response.data.status === 'nok'){
                        dispatch(checkoutActions.updateCart_Failure(response.data));
                    }
                })
            }
        catch (e) {
            return 
        }
    }
}
export const removeCart = (cart_id) => {
    var userDetails = localStorage.getItem("userDetails");
    var data = JSON.parse(userDetails);
    var user_id = data?.ID;
    return async (dispatch) => {
        try {
            await mgtApi.post('user-remove-cart',{cart_id:cart_id,user_id:user_id})
                .then((response) => {
                    
                    if(response.data.status === 'ok'){
                        dispatch(checkoutActions.removeSuccess(response.data.message));
                        
                    }
                    else{
                        dispatch(checkoutActions.updateCartFailure(response.data));
                    }

                    dispatch(user_checkout())
                })
            }
        catch (e) {
            return 
        }
    }
}
export const checkExpress = (shipping_profile,is_checked) => {
    var userDetails = localStorage.getItem("userDetails");
    var data = JSON.parse(userDetails);
    var user_id = data?.ID;
    return async (dispatch) => {
        try {
            await mgtApi.post('check-express',{shipping_profile:shipping_profile,user_id:user_id,is_checked:is_checked})
                .then((response) => {
                    
                    if(response.data.status === 'ok'){
                        if(is_checked === 1){
                        dispatch(checkoutActions.checkexpress_check(response.data.status,is_checked));
                        }
                        if(is_checked === 0){
                            dispatch(checkoutActions.checkexpress_uncheck(response.data.status,is_checked));
                            }
                    }
                    
                })
            }
        catch (e) {
            return 
        }
    }
}
export const checkGift = (shipping_profile,is_checked) => {
    var userDetails = localStorage.getItem("userDetails");
    var data = JSON.parse(userDetails);
    var user_id = data?.ID;
    return async (dispatch) => {
        try {
            await mgtApi.post('check-gift',{shipping_profile:shipping_profile,user_id:user_id,is_checked:is_checked})
                .then((response) => {
                    
                    if(response.data.status === 'ok'){
                        if(is_checked === 1){
                        dispatch(checkoutActions.checkgift_check(response.data.status,is_checked));
                        }
                        if(is_checked === 0){
                            dispatch(checkoutActions.checkgift_uncheck(response.data.status,is_checked));
                            }
                    }
                    dispatch(user_checkout())
                    
                })
            }
        catch (e) {
            return 
        }
    }
}
export const getUserAddress = (input) => {


    return async (dispatch) => {
        try {
            await mgtApi.get('/get-address', input).then(response => {
                if (response.data.status == "success") {
                    dispatch(checkoutActions.userAddressDetailsSuccess(response.data.data.address))
                }

            })
        }
        catch (e) {
            return 
        }
    }
}
export const updateDefaultAddress = (addressId) => {
  

    return async (dispatch) => {
        try {
            await mgtApi.post('/update-address-type', {address_for:'shipping',id:addressId}).then(response => {
                if (response.data.status == "success") {
                    dispatch(checkoutActions.addressUpdate(response.data.status))
                }

            })
        }
        catch (e) {
            return 
        }
    }
}


export const addressUpdateModalOpen = () => {
    return async (dispatch) => {
        try{
            dispatch(checkoutActions.addressUpdateModalOpen(true))
        }catch(e){
            return;
        }
    }
}


export const updateCartQuantity = (payload) => {
    return async (dispatch) => {

        try {
            const productData = new FormData();
            productData.append("product_id", payload.product_id)
            productData.append("quantity", payload.quantity)
            productData.append("inventory_id", payload.inventory_id)
            productData.append("user_id", payload.user_id)

            await mgtApi.post('user-create-cart-web',productData)
                .then((response) => {
                    
                    if(response.data.status === 'ok'){
                        dispatch(checkoutActions.updateCartSuccess(response.data.message));
                        
                    }
                    else{
                        dispatch(checkoutActions.updateCartFailure(response.data));
                    }
                    dispatch(user_checkout())
                })
            }
        catch (e) {
            return 
        }
    }
}

export default checkoutSlice.reducer;