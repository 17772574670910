import { configureStore } from "@reduxjs/toolkit";
import homeReducer from './home';
import authReducer from './auth';
import productReducer from './product';
import checkoutReducer from '../checkoutpage/checkout_reducer';
import checkout from "./checkout";
import cart from "./cart";

const store = configureStore({
    reducer: {
        home: homeReducer,
        auth: authReducer,
        prodLi:productReducer,
        checkoutp:checkoutReducer,
        checkout: checkout,
        cart: cart
    }
})



export default store;