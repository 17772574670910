import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductByCategory,
  getFiltercode,
  addWishlist,
} from "../store/product";
import SiteLoader from "../SiteLoader";
import defaultImg from "../components/img/default.png";
import { images } from "../utils/images";
import { mgtApi } from "../store/axios";
import InfiniteScroll from "react-infinite-scroller";
import { Circles } from "react-loader-spinner";
import Filters from "../components/General/Filters";

const ProductByCategory = () => {
  const dispatch = useDispatch();
  const catslug = window.location.pathname.split("product-by-category/").pop();
  const [categoryId, setCategoryId] = useState([])
  const catAndSubCatSlugs = catslug.split("/");
  const categorySlug = catAndSubCatSlugs[0];

  const {headerCategories} = useSelector(state=> state.home);

  const navigate = useNavigate();

  const subCategorySlug =
    catAndSubCatSlugs.length === 1 ? categorySlug : catAndSubCatSlugs[1];

  useEffect(() => {
    setProducts([]);
    setPage(2);
    setLoadMore(true)
    dispatch(getProductByCategory({ catslug }));
    setCategoryId(headerCategories.filter(item=> item.slug === catslug))
  }, [catslug]);

  useEffect(()=>{
    setCategoryId(headerCategories.filter(item=> item.slug === catslug))
  }, [headerCategories])

  useEffect(()=>{
    if(categoryId){
      dispatch(getFiltercode({ cate_id: categoryId[0]?.cat_id }));
    }
  }, [categoryId])

  const [products, setProducts] = useState([]);
  const [listStyle, setListStyle] = useState(false);

  const productAll = useSelector((state) => state.prodLi.productByCategory);

  const filteredProducts = useSelector(
    (state) => state.prodLi.filter_array_products
  );

  const prodli = productAll.data;
  const state = useSelector((state) => state.prodLi);

  useEffect(() => {
    if (prodli?.product?.length > 0) {
      setProducts(prodli.product);
    }
  }, [prodli]);

  useEffect(() => {
    if (filteredProducts?.products?.length > 0) {
      setProducts(filteredProducts.products);
    }
  }, [filteredProducts]);

  const filter_array = state.filterList;

  const countlength = () => {
    return (
      <div className="counter">
        <strong>{state.plength}</strong>
      </div>
    );
  };

  
  var userDetails = localStorage.getItem("userDetails");
  var data = JSON.parse(userDetails);
  const addToWishList = (product_id, index) => {
    dispatch(addWishlist(product_id));

    var heartIcon = document.getElementById("heartIcon-" + index);
    heartIcon.classList.toggle("selected");
    heartIcon.classList.toggle("hearticoncolor");
  };
  const onProductClick = (link) => {
    navigate(link);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  
  // --------------------------------------------

  const [smallScreen, setSmallScreen] = useState(false)
  const [page, setPage] = useState(2);
  const [loadMore, setLoadMore] = useState(true);
  const [totalPages, setTotalPages] = useState(3)

  async function loadMoreProducts() {
    if (page > 1 && loadMore && products.length > 0) {
      if (page >= totalPages || products.length < 10) {
        setLoadMore(false);
      } else {
        let userDetails = localStorage.getItem("userDetails");
        let userData = JSON.parse(userDetails);
        let user_id = userData?.ID;
        const { data } = await mgtApi.post("product-by-category-web", {
          page,
          slug: categorySlug,
          user_id,
        });
        setTotalPages(data.total_pages)
        setProducts([...products, ...data?.data?.product]);
        setPage(page + 1);
      }
    }
  }

  return (
    <>
      {state.loaderStatus ? (
        <SiteLoader status={state.loaderStatus} />
      ) : (
        <>
          <div>
            <main>
              <div className="bg-light inner-breadcrumb">
                <div className="container">
                  <div className="breadcrumb-head">
                    <h3>{categorySlug}</h3>
                    <nav className="breadcrumb-wrap">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {categorySlug}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <SiteLoader status={state.loaderStatus} />
              <section className="single-wrapper">
                <div className="container">
                  <div className="flex-space-between pt-4 pb-4">
                    {countlength()}
                    <div className="product-filter">
                      <div className="view-style">
                        <h3>Views : </h3>
                        {listStyle ? (
                          <button onClick={(e) => setListStyle(false)}>
                            <img src={images["grid.svg"]} alt="" />
                          </button>
                        ) : (
                          <button>
                            <img
                              src={images["list.svg"]}
                              alt=""
                              onClick={(e) => setListStyle(true)}
                            />
                          </button>
                        )}
                      </div>
                      <button className="filter-btn" onClick={e=>setSmallScreen(true)}>Filters</button>
                    </div>
                  </div>
                  {/* <!-- product  --> */}
                  <div className="row">
                    <div className={`col-lg-3 col-md-4 ${smallScreen ? 'resp-show' : 'resp-hide'}`}>
                      <Filters options={filter_array} setSmallScreen={setSmallScreen}/>
                    </div>
                    <div className="col-lg-9 col-md-8">
                    <InfiniteScroll
                    pageStart={2}
                    className="flex-product flexgrow"
                    loadMore={loadMoreProducts}
                    hasMore={loadMore}
                    loader={
                      <div className="spinner">
                        <Circles
                          type="Circles"
                          color="#f6a92c"
                          height={60}
                          width={60}
                        />
                      </div>
                    }
                  >
                    {products?.map((plist, index) => {
                      return (
                        <div className={listStyle ? "list-item" : "item"} key={index}>
                          <div className="product-row wow animated fadeInUp">
                            <span
                              onClick={() =>
                                onProductClick(
                                  `/shop/${categorySlug}/${subCategorySlug}/${plist.pro_slug}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="product-cover"
                                style={listStyle ? { width: "200px" } : {}}
                              >
                                <img
                                  src={plist.main_image}
                                  className="img-fluid"
                                  title=""
                                  alt=""
                                  onError={(e) => (e.target.src = defaultImg)}
                                />
                                <div className="wishlist-icon">
                                  <i
                                    className={
                                      plist?.wishlist_status
                                        ? "fa fa-heart hearticoncolor selected"
                                        : "fa fa-heart"
                                    }
                                    aria-hidden="true"
                                    wish-class
                                    id={`heartIcon-${index}`}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addToWishList(plist.id, index);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </span>
                            {/* <!-- product-cont  --> */}
                            <div className="product-content p-3">
                              <h4>
                                {plist.pro_name.length > 15
                                  ? plist.pro_name.slice(0, 15) + "..."
                                  : plist.pro_name}
                              </h4>
                              <div className="price">
                                <div className="grey-text">
                                  <span className="text-warning">
                                    ${plist.sell_price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </section>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default ProductByCategory;
