import React, { useEffect, useState } from "react";
import FirstPaint from "./Components/FirstPaint";
import TeamCard from "./Components/TeamCard";
import {mgtApi} from '../../store/axios'

const About = () => {
  const [about, setAbout] = useState(null);
  useEffect(()=>{
    mgtApi.post('about-us').then(r=>console.log(r))
  }, [])
  return (
    <section>
      <FirstPaint />
      <div className="container">
        <div className="problems row">
          <div className="col-lg-6 padding-x">
            <span>Problems Trying</span>
            <h3>
              Met minim Mollie non desert Alamo est sit cliquey dolor do met
              sent.
            </h3>
          </div>
          <div className="col-lg-6 float-r">
            <p>
              Problems trying to resolve the conflict between the two major
              realms of Classical physics: Newtonian mechanics
            </p>
          </div>
        </div>
        <div className="happy-customers">
          <div className="row">
            <div className="col-lg-3">
              <h1>15K</h1>
              <h5>Happy Customers</h5>
            </div>
            <div className="col-lg-3">
              <h1>15K</h1>
              <h5>Happy Customers</h5>
            </div>
            <div className="col-lg-3">
              <h1>15K</h1>
              <h5>Happy Customers</h5>
            </div>
            <div className="col-lg-3">
              <h1>15K</h1>
              <h5>Happy Customers</h5>
            </div>
          </div>
        </div>
        <div className="video-box">
          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/D0UnqGm_miA"
            title="Dummy Video For Website"
            frameborder="0"
            allow="accelerometer; autoplay"
            allowfullscreen
          ></iframe>
        </div>
        <div className="our-team">
          <h2>Meet Our Team</h2>
          <p>
            Problems trying to resolve the conflict between the two major realms
            of Classical physics: Newtonian mechanics{" "}
          </p>
          <TeamCard/>
        </div>
      </div>
    </section>
  );
};

export default About;
