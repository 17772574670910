import { Link } from "react-router-dom";
// import React, { useReducer } from 'react';
import React, {  } from 'react';
import { useState, useEffect} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { validatelogin, checkLogin, manageLoginmessage } from "../store/auth";

import {useNavigate} from 'react-router-dom'

const Login = () => {
  const dispatch = useDispatch();

  const intialValues = { email:"",password:""}

  const navigate = useNavigate()


  const login_fields = useSelector(state => state.auth.login)
  const state = useSelector(state => state.auth)

  useEffect(() => {
}, [login_fields])

useEffect(() => {
  if(state.loginStatus){
    localStorage.setItem("userDetails", JSON.stringify(state.userDetails))
      navigate(-1)
      // navigate('/');
    // var prev_url = localStorage.getItem("prev_url") || "/";
    // console.log("prev_url", prev_url)
    // navigate(prev_url)
    
  }
}, [state.loginStatus])


  const[error,setErrors]=useState(false)
    const handellogin = (e) =>{ 
     
      dispatch(manageLoginmessage())
        const { name, value } = e.target;
       
    
        var input = {field: name, value: value}

        dispatch(validatelogin(input))
    };


    const handelSubmit = (e) =>{
      e.preventDefault();

     if(validate(login_fields)){
      dispatch(checkLogin(login_fields))
     }
    }
 
  const validate = (values) => {
         const error = {};
         const regex= /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
         const pass_regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
         
         if(!values.password){
             error.password = "password is required!"
         }
        
         if(!values.email){
             error.email = "Email is required!"
         }
         else if(!regex.test(values.email)){
             error.email = "Plese enter the correct format of the email."
         }
         setErrors(error);
         return Object.values(error).length > 0 ? false : true;
 }


const showWrongpasswordmsg = () => {
        
  
  if(state.loginErrormsg){
    //document.getElementById('log-error')?.classList.remove("hide");
      return  <div className="alert alert-danger set-margin-top" id="log-error" role="alert">{state.loginError}</div>
     
  } 
  
}


// REMEMBER ME FUNCTIONALITY START
const [rememberMe, setRememberMe] = useState(false);
const handleCheckboxChange = (event) => {
  setRememberMe(event.target.checked);
};
// REMEMBER ME FUNCTIONALITY END


  return (
    <div>
        <main>
  <div className="bg-light inner-breadcrumb">
    <div className="container">
      <div className="breadcrumb-head">
        <h3>Login</h3>
        <nav className="breadcrumb-wrap">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page" style={{'fontSize':'12px', 'paddingTop':'3px'}}>Login</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <section className="single-wrapper section-wrapper">
    <div className="container">
      <div className="row pt-2 pb-5 justify-content-center">
        <div className="col-md-4 login-wrap">
          {/* <h3 className="text-center">Welcome Back</h3> */}
          
         {showWrongpasswordmsg()}
         <form onSubmit={handelSubmit} method="">
           <div className="form-group">
             <label for="">Email Address</label>
             <input type="text" name="email" className="form-control" onChange={handellogin} placeholder="Enter email"/>
           </div>
           <p className="register_error">{error.email}</p>
           <div className="form-group">
             <label for="">Password</label>
             <input type="password" name="password" className="form-control" onChange={handellogin} placeholder="Enter Password"/>
           </div>
           <p className="register_error">{error.password}</p>
           <div className="row checkbox px-3 mb-2">
              <div className="col-6">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" checked={rememberMe} onChange={handleCheckboxChange}  value="" id="flexCheckDefault"/>
                <label className="form-check-label" for="flexCheckDefault">
                     Remember me
                </label>
              </div>
              </div>
              <div className="col-6">
              <Link to="/forgot-password" style = {{color:'red', fontWeight:'bold', float:'right'}}>Lost your password?</Link>
              </div>
            </div>
           <button disabled={state.loader} className="btn btn-warning w-100" style={{'font-weight':'700'}}>{state.loader ? "Logging in..." : "Login"}</button>
         </form>
         <div className="ac-open text-center mt-3">Don't have an account? <a href="/register" style={{'color': '#fd7e14', 'font-weight': '600' }}>Create Now</a></div>
         
        </div>
      </div>
    </div>
  </section>
  
</main>
    </div>
  )
}

export default Login