import React from 'react'

const Category = () => {
  return (
    <div>
        <main>
  <div className="bg-light inner-breadcrumb">
    <div className="container">
      <div className="breadcrumb-head">
        <h3>Categories</h3>
        <nav className="breadcrumb-wrap">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">All Categories</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <section className="single-wrapper">
    <div className="container">
      <div className="flex-space-between pt-4 pb-4">
        <div className="counter grey-text">Showing all 12 results </div>
        <div className="product-filter">
          <div className="views-setting">
            <h4 className="grey-text">Views:</h4>
            <a href=" "><i className="ri-layout-grid-fill"></i></a>
            <a href="#link"><i className="ri-list-check-2"></i></a>
          </div>
          <select name="" className="form-select" id="">
            <option value="">Popularity</option>
            <option value="">Latest</option>
            <option value="">Oldest</option>
          </select>
          <button className="btn btn-md btn-warning">Filter</button>
        </div>
      </div>
      {/* <!-- categories list  --> */}
      <div className="row categories-row pb-5">
        <div className="col-md-4">
          <div className="flex-colmn mx-100 mb-4 h-auto wow animated fadeInUp">
            <img src="assets/img/img1.jpg" className="img-fluid" title="" alt=""/>
            <a href="#link" className="overlay-heading">Fine Jewelery</a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="flex-colmn mx-100 mb-4 h-auto wow animated fadeInUp">
            <img src="assets/img/img1.jpg" className="" title="" alt=""/>
            <a href="#link" className="overlay-heading">Fine Jewelery</a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="flex-colmn mx-100 mb-4 h-auto wow animated fadeInUp">
            <img src="assets/img/img1.jpg" className="" title="" alt=""/>
            <a href="#link" className="overlay-heading">Fine Jewelery</a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="flex-colmn mx-100 mb-4 h-auto wow animated fadeInUp">
            <img src="assets/img/img1.jpg" className="" title="" alt=""/>
            <a href="#link" className="overlay-heading">Fine Jewelery</a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="flex-colmn mx-100 mb-4 h-auto wow animated fadeInUp">
            <img src="assets/img/img1.jpg" className="" title="" alt=""/>
            <a href="#link" className="overlay-heading">Fine Jewelery</a>
          </div>
        </div>
        <div className="col-md-4">
          <div className="flex-colmn mx-100 mb-4 h-auto wow animated fadeInUp">
            <img src="assets/img/img1.jpg" className="" title="" alt=""/>
            <a href="#link" className="overlay-heading">Fine Jewelery</a>
          </div>
        </div>
      </div>
      {/* <!-- end  --> */}
    </div>
  </section>
  <hr/>
  {/* <!-- clients  --> */}
  <section className="section-wrapper">
    <div className="container">
      <div className="clients-wrap">
        <h4 className="text-center">Trusted By Over 4000 Big Companies</h4>
        <div className="clientLogo">
          <a href="#link"><img src="assets/img/logo1.png" className="img-fluid" title="" alt=""/></a>
          <a href="#link"><img src="assets/img/logo2.png" className="img-fluid" title="" alt=""/></a>
          <a href="#link"><img src="assets/img/logo3.png" className="img-fluid" title="" alt=""/></a>
          <a href="#link"><img src="assets/img/logo4.png" className="img-fluid" title="" alt=""/></a>
          <a href="#link"><img src="assets/img/logo5.png" className="img-fluid" title="" alt=""/></a>
          <a href="#link"><img src="assets/img/logo6.png" className="img-fluid" title="" alt=""/></a>
        </div>
      </div>
    </div>
  </section>
</main>
    </div>
  )
}

export default Category