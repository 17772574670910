import React, { useReducer } from 'react';
import { useState, useEffect} from 'react';
import axios from "axios";
// import { Link } from 'react-router-dom';
// import { loginUser, signupUser, fbLogin, googleLogin, userActions,fetchSocialCredential } from '../store/user';
import { useDispatch, useSelector } from 'react-redux';

import { updateField, RegisterUser, updateSetAlreadyRegister } from "../store/auth";
// import { object } from 'yup';
import {Link, useNavigate} from 'react-router-dom'
import { error } from 'jquery';

const Register = () => {
    //document.getElementById('email-is-register')?.classList.add("hide");
const dispatch = useDispatch();

    const intialValues = { full_name:"",email:"",mobile:"",password:"",cpassword:""}

    const navigate = useNavigate()

    const form_fields = useSelector(state => state.auth.form_fields)
    const state = useSelector(state => state.auth)
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
    }, [form_fields])

    useEffect(() => {
        if(state.registerStatus){
            localStorage.setItem('registerdEmail', form_fields['email']);
           
            
            navigate('/authentication')
            
        }
        
    }, [state.registerStatus])

    if(state.alreadyRegister){
        setTimeout(() => {
            dispatch(updateSetAlreadyRegister())
        }, ((5000)));
    }

    const checkIsRegister = () => {
        
        
        if(state.alreadyRegister){
            //document.getElementById('reg_btn')?.classList.add("hide");
           // document.getElementById("myform").reset();
            return <div className="alert alert-danger register-msg-style" role="alert" id="email-is-register">Email is already registered with us.Try another.</div>
            
        } 
        else{
            document.getElementById('email-is-register')?.classList.add("hide");
            // return   <Link to="/login" className="text-warning"><strong>Login</strong></Link>
            

        }
      }
   
      
    //const[errors,setErrors]=useState(false)
    const [errors, setErrors] = useState({});
    const handleChangeUpdate = (e) =>{
        
        const { name, value } = e.target;
        var input = {field: name, value: value}
        if(value){
            errors[name] = "";
            setErrors(errors)
        }
        dispatch(updateField(input))
        
    };

    //create a state
   //const[formValues, setFormValues]=useState(intialValues);
 


   
    const handelSubmit = (e) =>{
        e.preventDefault();

       if(validate(form_fields)){
         dispatch(RegisterUser(form_fields))
       }
       
        
    }

 
    const validate = (values) => {
            const error = {};
            const regex= /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i;
            const pass_regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
            if(!values.full_name || /^\s+$/.test(values.full_name)){
                error.full_name = "Username is required!"
            }
            if(!values.password){
                error.password = "password is required!"
            }
            // if(!values.password.length < 6){
            //     error.password = "The password must be at least 6 characters!"
            // }
            if(!pass_regex.test(values.password)){
                error.password = "6 letter password, with at least a symbol, upper and lower case letters and a number."

            }
            if(!values.email){
                error.email = "Email is required!"
            }
            else if(!regex.test(values.email)){
                error.email = "Plese enter the correct format of the email."
            }
            if(!values.mobile){
                error.mobile = "Phone Number is required!"
            }
            else if (!/^\d{10,14}$/.test(values.mobile)) {
                error.mobile = "Invalid Phone Number. Please enter a valid numeric number.";
              }
            else if (/\s{2,}/.test(values.mobile)) {
                error.mobile = "Phone Number should not contain multiple spaces!";
              }
            //   else if (!/^\d+$/.test(values.mobile)) {
            //     error.mobile = "Invalid Phone Number. Please enter a valid numeric number.";
            //   }
            else if (!/^\d+$/.test(values.mobile)) {
                error.mobile = "Invalid Phone Number. Please enter a valid numeric number.";
              }
              
            if(!values.cpassword){
                error.cpassword = "Confirm Password is required!"
            }
            else if(values.cpassword !== values.password){
                error.cpassword = "Confirm Password and Password should be same." 
            }
            setErrors(error);
            return Object.values(error).length > 0 ? false : true;
    }

    const handleKeyUp = (event) => {
        const inputValue = event.target.value;
        if (!/^\d+$/.test(inputValue)) {
          // Display an error or perform any other validation logic
       
            errors["mobile"] = "Invalid Phone Number. Please enter a valid numeric number.";
            setErrors(errors)
            setUpdated(!updated);
        //   event.target.value = ""; // Clear the field
        }
      };

    return (
        <div>
            <main>
            <div className="bg-light inner-breadcrumb">
                <div className="container">
                <div className="breadcrumb-head">
                    <h3>Register</h3>
                    <nav className="breadcrumb-wrap">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page" style={{'fontSize':'12px', 'paddingTop':'3px'}}>Register</li>
                    </ol>
                    </nav>
                </div>
                </div>
            </div>
           
            <section className="single-wrapper section-wrapper">
                <div className="container">
                    {/* <pre>{JSON.stringify(formValues,undefined,2)}</pre> */}
                    <div className="row pt-2 pb-5 justify-content-center">
                        <div className="col-md-6 login-wrap">
                            <h3 className="text-center" style ={{'color':'#ffc107'}}>Welcome To Mangtum</h3>
                            {checkIsRegister()}
                            <form onSubmit={handelSubmit} id="myform">
                                <div className="form-group">
                                    <label>Name<span style={{'color':'red'}}>*</span></label>
                                    <input type="text" name="full_name"  onChange={handleChangeUpdate} className="form-control" placeholder="Your Full Name"/>
                                </div>
                                <p className="register_error">{errors.full_name}</p>

                                <div className="form-group">
                                    <label>Email Address<span style={{'color':'red'}}>*</span></label>
                                    <input type="text" name="email"  onChange={handleChangeUpdate} className="form-control" placeholder="example@email.com"/>
                                </div>
                                <p className="register_error">{errors.email}</p>
                                <div>
                                    <label>Phone Number<span style={{'color':'red'}}>*</span></label>
                                <div className="reg-form-group">
                                    
                                    <input type="phone" minLength={10} maxLength={10} name="mobile" onChange={handleChangeUpdate} className="form-control" placeholder="phone number" onKeyUp={handleKeyUp}
/>
                                </div>
                                </div>
                                <p className="register_error">{errors.mobile}</p>
                                <div className="form-group">
                                    <label>Password<span style={{'color':'red'}}>*</span></label>
                                    <input type="password" name="password"  onChange={handleChangeUpdate} className="form-control" placeholder="password"/>
                                </div>
                                <p className="register_error">{errors.password}</p>
                                <div className="form-group">
                                    <label>Confirm Password<span style={{'color':'red'}}>*</span></label>
                                    <input type="password" name="cpassword"  onChange={handleChangeUpdate} className="form-control" placeholder="confirm password"/>
                                </div>
                                <p className="register_error">{errors.cpassword}</p>
                                <div className="col-6 lost">
                                  <button disabled={state.loader} className="btn btn-warning w-100" id="reg_btn"  style={{'font-weight':'700'}}>{state.loader ? "Registering..." : "Register"}</button>  
                                </div>

                            </form>

                        </div>
                    </div>
                </div>




            </section>
            </main>
        </div>
    );
}

export default Register;