import React from 'react'
import { useState, useEffect } from 'react';
import SiteLoader from '../SiteLoader'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import product, { addWishlist, getWishlistProduct, deleteWishlistProduct } from '../store/product';
import { useDispatch, useSelector } from 'react-redux';
import emptyWishImg from '../components/img/nowish.jpg';



const UserWishlist = () => {
  // const location = useLocation();
  const dispatch = useDispatch();
  const [loader, setLoaderStatus] = useState(true);

  // const isLogin = useSelector(state => state.user.isLogin);
  useEffect(() => {
    dispatch(getWishlistProduct());
  }, []);

  const wishlists = useSelector(state => state.prodLi.wishList);
  const wishlist_status = useSelector(state => state.prodLi.wishlist_status);
  const loaderStatus = useSelector(state => state.prodLi.loaderStatus);

  const AddToCart = (product) => {
    console.log("product_id", product)
  }

  // const handleDelete = (event, addressId) => {
  //   event.stopPropagation();
  //   if(window.confirm("Are you sure you want to delete this product?")){
  //   dispatch(getUserAddressDelete(addressId));
  //   dispatch(getUserAddress());
  //   }
  // };

  const DeleteFromWishList = (wishlist) => {
    console.log("wishlist", wishlist);
    dispatch(addWishlist(wishlist.id))
  }
  
  useEffect( () => {
    console.log("wish list ", wishlist_status)
    if(wishlist_status === 0) {
      dispatch(getWishlistProduct())
    }
  }, [wishlist_status])

  return (
    <>
    {loaderStatus ? <SiteLoader status={loaderStatus} /> :
    <>
      <div className="bg-light inner-breadcrumb">
        <div className="container">
          <div className="breadcrumb-head">
            <h3>Wishlist</h3>
            <nav className="breadcrumb-wrap">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page"  style={{'fontSize':'12px', 'paddingTop':'3px'}}>Wishlist</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <section id="cart-view">
        <div className="container">
          <div className="row">
            {wishlists?.products?.length > 0 ? (
              <>
                <div className="col-md-12">
                  <div className="cart-view-area">
                    <div className="cart-view-table aa-wishlist-table">
                      <form action="#" method="post">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Image</th>
                                <th>Product</th>
                                <th>Regular Price</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {wishlists?.products?.map((wishlist) => (
                                <tr key={wishlist.id}>
                                  <td>
                                    <a className="remove" href="https://www.free-css.com/free-css-templates">
                                      <i className="fa fa-close"></i>
                                    </a>
                                  </td>
                                  <td>
                                    <Link className="aa-cart-title wishimg" to={`/shop/${wishlist.cat_slug}/${wishlist.subcat_slug}/${wishlist.pro_slug}`}>
                                      <img  src={wishlist.main_image} alt="website template image" style={{ height: '100px' }} />
                                    </Link>
                                  </td>
                                  <td>
                                    <Link className="aa-cart-title addcartclr" to={`/shop/${wishlist.cat_slug}/${wishlist.subcat_slug}/${wishlist.pro_slug}`}>
                                      {wishlist?.product_name.length > 20 ? wishlist.product_name.slice(0, 20) + '...' : wishlist.product_name}
                                    </Link>
                                  </td>
                                  <td>${wishlist?.regular_price}</td>
                                  <td>
                                  <button onClick={() => DeleteFromWishList(wishlist)} type="button" className="btn-sm btn-warning removebtn" >Delete</button>
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>) : (
              <>
                <div className='not-found col-md-6 offset-md-3' >
                  <img src={emptyWishImg} className="img-fluid" title="" alt="" style={{ 'height': '300px', 'width': '300px' }} />
                  <div className='not-found col-md-12' style={{ 'line-height': '30px' }} >
                    <p style={{  'font-size': '35px' }}><strong>Your wishlist is empty</strong></p>
                    <h5>Create your first wishlist request</h5><br />
                    <strong><span className="explore-more">
                      <Link to="/">
                        <div className="waviy">
                          <span style={{ '--i': '1' }}>S</span>
                          <span style={{ '--i': '2' }}>H</span>
                          <span style={{ '--i': '3' }}>O</span>
                          <span style={{ '--i': '4' }}>P</span>&nbsp; &nbsp;
                          <span style={{ '--i': '5' }}>N</span>
                          <span style={{ '--i': '6' }}>O</span>
                          <span style={{ '--i': '7' }}>W</span>

                        </div>
                      </Link></span></strong>

                  </div>
                </div>

              </>
            )}
          </div>
        </div>
      </section>
    </>
    }
    </>
  );
}

export default UserWishlist