import "../App.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiltercode, getFilterProduct, addWishlist } from "../store/product";
import defaultImg from "../components/img/default.png";
import SiteLoader from "../SiteLoader";
import { images } from "../utils/images";
import InfiniteScroll from "react-infinite-scroller";
import { Circles } from "react-loader-spinner";
import { mgtApi } from "../store/axios";
import Filters from "../components/General/Filters";

const Productsearch = () => {
  const search_state = useSelector((state) => state.home);
  const [searchArray, setSearchArray] = useState([]);

  const dispatch = useDispatch();

  const [listStyle, setListStyle] = useState(false);

  const state = useSelector((state) => state.prodLi);

  const cate_id = state.cate_id ? state.cate_id : 1;
  useEffect(() => {
    setPage(2);
    setLoadMore(true);
    dispatch(getFiltercode({ cate_id }));
  }, [cate_id]);
  const filter_array = state.filterList;

  const st = useSelector((state) => state.home);

  const addToWishList = (product_id, index) => {
    dispatch(addWishlist(product_id));

    var heartIcon = document.getElementById("heartIcon-" + index);
    heartIcon.classList.toggle("selected");
    heartIcon.classList.toggle("hearticoncolor");
  };
  // -------------------------------------------------------
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    setPage(2);
    setLoadMore(true);
    setSearchArray(search_state?.search_array);
  }, [search_state?.search_array]);

  const [page, setPage] = useState(2);
  const [loadMore, setLoadMore] = useState(true);
  const [totalPages, setTotalPages] = useState(3);
  async function loadMoreProducts() {
    if (page > 1 && loadMore && searchArray.length > 0) {
      if (page >= totalPages || searchArray.length < 10) {
        setLoadMore(false);
      } else {
        const { data } = await mgtApi.post("productsearch", {
          page,
          keyword: search_state?.search_item,
        });
        setTotalPages(data.total_page);
        setSearchArray([...searchArray, ...data?.products]);
        setPage(page + 1);
      }
    }
  }

  return (
    <>
      {st.loaderStatus ? (
        <SiteLoader status={st.loaderStatus} />
      ) : (
        <>
          <div>
            <main>
              <div className="bg-light inner-breadcrumb">
                <div className="container">
                  <div className="breadcrumb-head">
                    <h3>Search Product</h3>
                    <nav className="breadcrumb-wrap">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Search Product
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div>
                <section className="single-wrapper">
                  <div className="container set-margin-btm">
                    <div className="filter-head row">
                      <div className="col-lg-5 col-12">
                        <div className="counter grey-text">
                          Showing all {searchArray.length} results{" "}
                        </div>
                      </div>
                      <div className="col-lg-7 col-12">
                        <div className="product-filter">
                          <div className="view-style">
                            <h3>Views : </h3>
                            {listStyle ? (
                              <button onClick={(e) => setListStyle(false)}>
                                <img src={images["grid.svg"]} alt="" />
                              </button>
                            ) : (
                              <button>
                                <img
                                  src={images["list.svg"]}
                                  alt=""
                                  onClick={(e) => setListStyle(true)}
                                />
                              </button>
                            )}
                          </div>
                          <button
                            className="filter-btn"
                            onClick={(e) => setSmallScreen(true)}
                          >
                            Filters
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-3 col-md-4 ${
                          smallScreen ? "resp-show" : "resp-hide"
                        }`}
                      >
                        <Filters
                          options={filter_array}
                          setSmallScreen={setSmallScreen}
                        />
                      </div>
                      <div className="col-lg-9 col-md-8">
                        <InfiniteScroll
                          pageStart={2}
                          className="flex-product flexgrow"
                          loadMore={loadMoreProducts}
                          hasMore={loadMore}
                          loader={
                            <div className="spinner">
                              <Circles
                                type="Circles"
                                color="#f6a92c"
                                height={60}
                                width={60}
                              />
                            </div>
                          }
                        >
                          {searchArray?.map((search_product, index) => {
                            return (
                              <div key={index}
                              className={listStyle ? "list-item" : "item"}
                            >
                              <div className="product-row wow animated fadeInUp">
                                <a
                                  href={`/shop/${search_product.cat_slug}/${search_product.subcategory_slug}/${search_product.pro_slug}`}
                                  rel="noopener noreferrer"
                                >
                                  <div className="product-cover">
                                    <img
                                      src={search_product.main_image}
                                      className="img-fluid"
                                      title=""
                                      alt=""
                                      onError={(e) =>
                                        (e.target.src = defaultImg)
                                      }
                                    />
                                    <div className="wishlist-icon">
                                      <i
                                        className={
                                          search_product?.wishlist_status
                                            ? "fa fa-heart hearticoncolor selected"
                                            : "fa fa-heart"
                                        }
                                        aria-hidden="true"
                                        wish-class
                                        id={`heartIcon-${index}`}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          addToWishList(
                                            search_product.id,
                                            index
                                          );
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </a>
                                {/* <!-- product-cont  --> */}
                                <div className="product-content p-3">
                                  <h4>
                                    {search_product.pro_name.length > 25 &&
                                    !listStyle
                                      ? search_product.pro_name.slice(
                                          0,
                                          25
                                        ) + "..."
                                      : search_product.pro_name}
                                  </h4>
                                  {listStyle && (
                                    <>
                                      <div className="desc">
                                        Saepe dolores il...
                                      </div>
                                      <div className="price">
                                        <div className="">
                                          <span className="">$300</span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default Productsearch;
