import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate, useNavigate, Link } from "react-router-dom";
import { getVendorinfo } from "../store/product";
import img1 from "../components/img/product-cover-1.png";
import img2 from "../components/img/product-cover-2.png";
import img3 from "../components/img/product-cover-3.png";
import img4 from "../components/img/product-cover-4.png";
import img5 from "../components/img/product-cover-5.png";
const SellerStore = () => {
  const dispatch = useDispatch();

  const { state } = useLocation();

 
  useEffect(() => {
    // Fetch vendor info using the getVendorinfo API call
    dispatch(getVendorinfo(state.vendor_id,state.shipping_profile));
  }, [state.vendor_id]);

  const vendorInfo = useSelector((state) => state.prodLi.vendorInfo);
  const { vendor: ven_profile, address: ven_addr, products: ven_prod } = vendorInfo;

  return (
    <div>
      <main>
        <section className="single-wrapper p-0 section-wrapper">
          {/* <!-- seller profile --> */}
          <div className="seller-profile bg-light pt-4 pb-4">
            <div className="container">
              <div className="flex-between">
                <div className="profile-dtl">
                  <h3>{ven_profile?.store_name}</h3>
                  <div className="content set-content-width">
                    {ven_profile?.bio}
                  </div>
                </div>
                <div className="profile_box">
                  <div className="profile-cover">
                    <img src="assets/img/team2.jpg" title="" alt="" />
                  </div>
                  <h3>{ven_profile?.name}</h3>
                  <div className="location"><i className="ri-map-pin-line"></i> {vendorInfo?.address?.city},<br/> {vendorInfo?.address?.state},<br/> {vendorInfo?.address?.country_name}</div>
                  <div className="rating">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star-half-o" aria-hidden="true"></i>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end profile  --> */}
          <div className="container">
            <div className="flex-space-between pt-4 pb-4">
              <div className="counter grey-text">Showing all {ven_prod?.length} results </div>
            </div>
            {/* <!-- product  --> */}
            <div className="flex-product flexgrow">
              {ven_prod?.map((plist) => (
                <div className="item" key={plist.product_slug}>
                  <div className="product-row wow animated fadeInUp">
                    <Link to={`/shop/${plist.category_slug}/${plist.subcategory_slug}/${plist.product_slug}`}>
                      <div className="product-cover">
                        <img src={plist.main_image} className="img-fluid" title="" alt="" />
                      </div>
                    </Link>    
                    {/* <!-- product-cont  --> */}
                    <div className="product-content p-3">
                      <h4> {plist.product_name.length > 15 ? `${plist.product_name.slice(0, 15)}...` : plist.product_name}</h4>
                      <div className="price">
                        <div className="grey-text">${plist.regular_price} <span className="text-warning">${plist.selling_price}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default SellerStore